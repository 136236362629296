<template lang="pug">
    .filter.w-100.h-100.d-flex 
        .drop-group
            .btn.btn-edit.h-100.d-flex.align-items-center.position-relative.dropdown-toggle(id="messageDrop" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                img.iconImg(src="@/assets/message.png")
                .content.overflow-auto.filterOptions.dropdown-menu.dropdown-menu-right(aria-labelledby="messageDrop" )
                    .row-no-gutters(v-for="msg in messages") 
                        .dropdown-item(data-toggle="collapse" @click="()=>{selectMsg(msg.id,msg.report_id)}" :class="{'unread':msg.status.includes('u')}")
                                img(v-if="msg.img" src="https://fakeimg.pl/300x200/" width="300" height="200")
                                div(v-if="isLanguageChinese") 新訊息：{{msg.subject_tw}}
                                div(v-else) 新訊息：{{msg.subject_en}}
                                .msgDate {{msg.date}}
                        .dropdown-divider
                    InfiniteLoader(ref="infinteLoader", @infinite="getMessageList")
                            
                    
              
</template>

<script>
import { getMsgList } from '../api/notification'
// import { getMsgDetail,getAppealList } from '../api/notification'
import InfiniteLoader from 'vue-infinite-loading'
// import Popup from '../components/Popup.vue'
export default {
    components: { InfiniteLoader}, 
    data() {
        return {
            page:1,
            messages:[
            ],
            msgDetail:{
                subject: "",
                description: undefined
                },
            appealMessageList:[]
        }
    },
    props: ["items"],
    mounted() {
        this. getMessageList(this.$refs.infinteLoader.stateChanger)
    },
    beforeDestroy(){
            
        },
    methods: {
        getMessageList($state){
            getMsgList("a",this.page,10).then(res=>{
            this.messages.push(...res.data.messages)
            console.log("MSG List")
            console.log(res.data)
            if(res.data.messages.length){
                this.page++
                $state.loaded()
            }else{
                $state.complete()
            }
        })
        },
        selectMsg(id,report_id){
            this.goToAppeal(report_id,id)
        },
        goToAppeal(report_id,id){
            const path = "/appeal/"+report_id+"/"+id
           if(this.$route.path !== path) {
               this.$router.push(path)
           }
        }

       
        
    },
    computed:{
        isLanguageChinese(){
            return  this.$store.getters.isLanguageChinese
        }
    }
}
</script>

<style lang="sass" scoped>
.msgDate
    font-size: 0.4rem
    color: #aaa
.unread
    background-color: #ffdbc1
.filter
    font-size: 0.8rem
.iconImg
    width: 15px
    height: 15px
.content
    // height: 150px
    position: absolute
    top: 100%
    z-index: 10
    background: #F7F7F7;
    border: 1.5px solid rgba(35, 35, 44, 0.9);
    box-sizing: border-box;
    border-radius: 5px;
    .row
        margin-bottom: 10px

input
    margin-right: 5px
.filterOptions
    padding: 5px
    max-height: 60vh
    // width: 25vw
    // min-width: 200px
    // max-width: 400px
    overflow: scroll
    .btn.btn-cancel
        font-size: 0.7rem !important
    label
        &:hover
            cursor: pointer
        margin: 0
</style>