export class SubscribeHistoryData{

    constructor({name,startDate,renewDate,status}){
        this.name = name
        this.startDate = startDate
        this.renewDate = renewDate
        this.status = status
    }
    get isOngoing(){
        return this.status == statusEnum.subscribed
    }
    get isSuspended(){
        return this.status == statusEnum.suspended
    }
    get statusString(){
        return Object.keys(statusEnum).filter(key=>{
            return statusEnum[key] == this.status
        })[0] || ""
    }
}


export let SubscriptionSuspendStatus = Object.freeze({
    cancel:"cancel",
    resume:"resume",
})
export let statusEnum = Object.freeze({
    subscribed:"Subscribed",
    suspended:"Suspended",
    canceled:"Non-subscribed"
})