<template lang="pug">
    //- .p-3
    .filter.w-100.h-100.d-flex
        //- .btn.btn-edit.d-flex.align-items-center(@click="showFilter = !showFilter; if(showFilter){mapAllFilterItems()}") 
        .drop-group
            .btn.btn-edit.h-100.d-flex.align-items-center.position-relative.dropdown-toggle(id="filterDrop" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                img(src="@/assets/options.png")
                .content.filterOptions.dropdown-menu(aria-labelledby="filterDrop" @click.stop)
                    .row.no-gutters
                        .col-12.font-weight-bold 
                            |{{materials.name}}
                            .ml-2.btn.btn-cancel.font-weight-bold(@click="selectAll(true)") {{$t('button.selectAll')}}
                            .ml-2.btn.btn-cancel.font-weight-bold(@click="selectAll(false)") {{$t('button.deselectAll')}}
                        .col-12.col-lg-6.mt-2.align-items-center(v-for="option in materials.options")
                            input(:id="materials.name+option.name" type="radio" @click="click(option)" :checked="option.selected")
                            label(:for="materials.name+option.name") {{option.displayName}}
                    .row(v-for="category in options")
                        .col-12.font-weight-bold.mt-1.mb-1 {{category.name}}
                        .col.row.m-0.align-items-center(v-for="option in category.options")
                            input(:id="category.name+option.name" type="radio" @click="click(option)" :checked="option.selected")
                            label(:for="category.name+option.name") {{option.name}}
        .drop-group.h-100.position-relative                  
            .btn.ml-3.h-100.dropdown-toggle.position-relative.d-flex.align-items-center.btn-cancel( id="sortDrop" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                | {{getSortByString()}}
                .dropdown-menu(aria-labelledby="sortDrop")
                    .dropdown-item(@click="setSortBy('c')") {{$t('utility.latest')}}
                    //- .dropdown-item(@click="setSortBy('p')") Popular
                    .dropdown-item(@click="setSortBy('d')") {{$t('utility.topDownloads')}}
        .searchInput.ml-3.mr-3.d-block.flex-fill
            input.h-100.w-100(v-model="searchTerm", @keyup.enter="search")
            .btn.searchBtn.d-flex.align-items-center(@click="search")
                img(src="@/assets/search.png")

                //- .dropdown-item 
</template>

<script>
export default {
    data() {
        return {
            showFilter:false,
            searchTerm:"",
            sortBy:"c",
            materials:{
                    name:this.$t('label.materials'),
                    options:[]
                },
            options: {
                
                machine_type:{
                    name:this.$t('label.laserModule'),
                    options:{
                        LC35:{name:'LC35',selected:false,value:"LC35"},
                        LC50:{name:'LC50',selected:true,value:"LC50"},
                    },
                },
                range:{
                    name:this.$t('label.sharedBy'),
                     options:{
                        official:{name:this.$t('title.cubiio_design'),selected:false,value:"official"},
                        user:{name:this.$t('label.userShare'),selected:true,value:"user"},
                    }
                },
                membership:{
                    name:this.$t('label.membership'),
                    options:{
                        Gold:{name:this.$t('label.golden'),selected:false,value:"Gold"},
                        Silver:{name:this.$t('label.silver'),selected:true,value:"Silver"},
                        Bronze:{name:this.$t('label.basic'),selected:false,value:"Bronze"},
                    },
                },
                searchFrom:{
                    name:this.$t('label.searchBy'),
                    options:{
                        Project:{name:this.$t('label.projectName'),selected:false,value:"Project"},
                        Author:{name:this.$t('label.authorName'),selected:true,value:"Author"},
                    },
                }

            },
        }
    },
    props: ["items"],
    beforeMount() {
        this.mapAllFilterItems()
        this.$bus.$on('materialListUpdate',()=>{
             this.mapAllFilterItems()
        })
    },
        beforeDestroy(){
            this.$bus.$off('materialListUpdate');
        },
    methods: {
        getSortByString(){
            switch(this.sortBy){
                case 'c':
                    // return "最新"
                    
                    return this.$t('utility.latest')
                     case 'd':
                    // return "最多下載"
                    return this.$t('utility.topDownloads')
                     case 'p':
                    // return "熱門"
                    return this.$t('utility.popular')
                    default:
                    // return "最新"
                    return this.$t('utility.latest')
            }
        },
        setSortBy(sortBy){
            this.sortBy = sortBy
        },
        search(){
            //收起Filter
            this.showFilter = false
            //寫入選項
            this.writeOptions()
            //跳轉頁面
            if(this.$router.currentRoute.path!="/projects/search"){
                this.$router.push("/projects/search").catch(()=>{})
               
            }
            this.$bus.$emit('filter', {});
        },
        writeOptions(){
                this.$store.commit("updateFilter",{
                    materials:this.materials.options,
                    options:this.options,
                    searchTerm:this.searchTerm,
                    sortBy:this.sortBy,
                })
        },
        click(value) {
            value.selected = !value.selected
            // console.dir(value)
            // this.$emit("search", this.options)
             this.writeOptions()
        },
        mapAllFilterItems() {
            //材質
            let materials = this.$store.state.materialList
            this.materials.options = materials
            //排序
            this.sortBy = this.$store.state.sortBy
            //選項
            let currentFilter =  this.$store.state.filterOptions
            for(let categoryKey in this.options){
                let filterCategory = currentFilter[categoryKey]
                if(filterCategory){
                    for(let optionKey in this.options[categoryKey].options){
                        let option = filterCategory.options[optionKey]
                        if(option){
                            this.options[categoryKey].options[optionKey].selected = option.selected
                        }
                    }
                }
            }


        },
        selectAll(all){
            // Object.values(this.options).forEach(category=>{
            //     Object.values(category.options).forEach(option=>{
            //         option.selected = all
            //     })
            // })
            this.materials.options.forEach(item=>{
                item.selected = all
            })
        }
    },
}
</script>

<style lang="sass" scoped>
.filter
    font-size: 0.8rem
    border-right: 2px grey solid
    img
        width: 15px
        height: 15px
    
.searchInput
    position: relative
.searchBtn
    position: absolute
    right: 0px
    top: 0
    height: 100%
.content
    position: absolute
    top: 100%
    z-index: 10
    background: #F7F7F7;
    border: 1.5px solid rgba(35, 35, 44, 0.9);
    box-sizing: border-box;
    border-radius: 5px;
    .row
        margin-bottom: 10px

input
    margin-right: 5px
.filterOptions
    padding: 15px
    max-height: 50vh
    width: 30vw
    min-width: 200px
    max-width: 350px
    overflow: scroll
    .btn.btn-cancel
        font-size: 0.7rem !important
    label
        &:hover
            cursor: pointer
        margin: 0
</style>