<template lang="pug">
    .container.h-100.d-flex.align-items-stretch.justify-content-center
        .row.pt-5
          .col-12.d-flex.justify-content-center.align-items-center
            .container
              .row.mt-3
                .form-inline
                  .code.position-relative
                    input.form-control(type="text" v-model="couponCode")
                    .btn.clearBtn(@click="clearCoupon") X
                  button.ml-2.btn.btn-outline-primary(@click="validateCoupon" :disabled="!isCouponCodeLengthValidate") 使用折價券
              .row
                .col-12(v-if="currentPrice")
                  del.small.text-muted  {{originalPrice}} USD
                  span.text-danger.ml-3  {{currentPrice}} USD
                .col-12.mt-3(v-else).text-center
                  .spinner-border
              .row.mt-5
                #paypal-button-container.col
</template>

<script>
import {getPaypalPlanId,reportPaypalResult} from '../api/payment'
export default {
  props:{
    originalPlanId:{
      typs:String
    }
    },
  data(){
    return {
      paypalButtons:undefined,
      paypalSDKReady:false,
      planIdWithoutCoupon:undefined,
      originalPrice:undefined,
      currentPrice:undefined,
      couponCode:"",
      plan_id:undefined
    }
  },
    mounted(){
      this.getPlanId(this.capitalizeFirstLetter(this.originalPlanId))
      let paypalScript = document.createElement('script')
      let lang = this.websiteLangToPaypalLangKey()
      const CLIENT_ID="Adlfw8V1FlMBEhNbyqtHL3Fo38e2ZA9PSovMo3-u2mSfueNF8VFTiBXp705OmsPTyp4gOFWLQcTknN89"
      paypalScript.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&vault=true&intent=subscription&locale=${lang}`)
      paypalScript.setAttribute('data-sdk-integration-source', 'button-factory')
      paypalScript.async = true
      paypalScript.onload = () => {
        this.paypalSDKReady = true
        this.onPaypalReady()
        }
      document.head.appendChild(paypalScript)
    },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getPlanId(originalPlanId,coupon){
      getPaypalPlanId(originalPlanId,coupon).then((res)=>{
        if(res.data && res.data.error){
          throw res.data
        }
        let {id,price } = res.data
       id = "P-6U072798BT5174528MDV37KA"
        // if(!id || !id.length){
        //   this.$alert("error")
        //   this.clearCoupon()
        //   return 
        // }
        if(!coupon){
          this.originalPrice = price
          this.planIdWithoutCoupon = id
        }
        this.plan_id = id
        this.currentPrice = price
      }).catch(async e=>{
        await this.$alert(e.response.data.reason)
        this.clearCoupon()
      })
    },
    websiteLangToPaypalLangKey(){
      return this.$store.getters.isLanguageChinese ? "zh_TW" : "en_US"
    },
    clearCoupon(){
      this.couponCode = undefined
      this.discountDescription = undefined
      this.plan_id = this.planIdWithoutCoupon
      this.currentPrice =  this.originalPrice
    },
    async validateCoupon(){
      await this.removePaypalButtons()
      this.plan_id = undefined
      this.currentPrice = undefined
      let token = this.couponCode.length ? this.couponCode : undefined
      this.getPlanId(this.capitalizeFirstLetter(this.originalPlanId),token)
    },
    async removePaypalButtons(){
      if(this.paypalButtons && this.paypalButtons.close){
          await this.paypalButtons.close()
        }
    },
    async onPaypalReady() {
        await this.removePaypalButtons()
        let paypal = window.paypal
        let plan_id = this.plan_id
        if(!paypal || !plan_id){
            console.warn("paypal script load fail.")
            return
        }
        console.log(paypal)
        let self = this
      this.paypalButtons = paypal
        .Buttons({
          style: {
            shape: "pill",
            color: "gold",
            layout: "vertical",
            label: "subscribe",
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              /* Creates the subscription */
              plan_id
            });
          },
        //   onApprove: function (data, actions) {
          onApprove: function (data) {
            let payloadToReport = {
                subscription_id:data.subscriptionID,
                // plan_id:"P-6U072798BT5174528MDV37KA",
                plan_id:self.originalPlanId,
                price:self.currentPrice,
                language: self.$store.getters.currentLang.id
            }
            reportPaypalResult(payloadToReport).then(()=>{
               self.$router.replace(`/${this.env}/subscription/success`)
            })
            console.log(payloadToReport); // You can add optional success message for the subscriber here
          },
        })

        this.paypalButtons.render("#paypal-button-container"); // Renders the PayPal button
    },
  },
  computed:{
    isCouponCodeLengthValidate(){
      return this.couponCode && this.couponCode.length >= 6
    },
  },
  watch:{
    plan_id(){
      if(this.paypalSDKReady){
        this.onPaypalReady()
      }
    }
  }
};
</script>

<style lang="sass">
.code
  .clearBtn
    position: absolute
    right: 0px
</style>