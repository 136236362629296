<template lang="pug">
  .container.mt-3
    .row(v-if="loading")
        .col.text-center
            .spinner-border.text-muted
    .row(v-else-if="items.length")
        .col-6.col-md-3.col-lg-2(v-for="item in itemsToImageCards" )
            ImageCard( :item="item")
    .row(v-else)
        .col.text-center.text-muted No result

</template>

<script>
import {getRedeemedGroup} from '../../api/userProfile'
import {Group} from '../../models/Group'
import LanguageEnum from '../../models/LanguageEnum'
import ImageCardProps from '../../models/ImageCardProps'
import ImageCard from '../ImageCard'
export default {
    components:{ImageCard},
    data() {
        return {
            loading:false,
            items:[]
        }
    },
    mounted() {
        this.loading = true
        getRedeemedGroup().then(res=>{
            // console.log(res)
            this.items = res.data.map(el=>{
                return new Group({
                    id:el.id,
                    imageLink:el.imageUrl,
                    title:
                        this.isCurrentLanguageChinese() ? 
                        el.title.tw :
                        el.title.en
                })
            })
        }).finally(()=>{
            this.loading = false
        })
    },
    methods:{
        isCurrentLanguageChinese(){
            return this.$store.getters.currentLang == LanguageEnum.zh
        }
    },
    computed:{
        itemsToImageCards(){
            return this.items.map(el=>{
                return new ImageCardProps({
                    image_url : el.imageLink,
                    title: el.title,
                    onclick:()=>{
                        console.log(`click on ${el.id}`)
                       this.$router.push(`/group/${el.id}`)
                    }
                })
            })
        }
    }
}
</script>

<style>

</style>