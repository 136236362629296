<template lang="pug">
div.h-100
    .container.d-flex.flex-column.bg-light.appeal
        a.lightbox(id="lightbox",href="#",)
            img.bg-white.w-50.h-50(:src="selectedImage")
        .row.header
            a.btn.px-3.py-1.col.dropdown-toggle(data-toggle="collapse" href='#caseInfoCollapse' role='button' aria-expanded='false' aria-controls='caseInfoCollapse') 案件資訊
            #caseInfoCollapse.collapse.w-100.show
                .card.card-body
                    .row.border
                        strong.col-12 {{i18nMessageDetailSubject}}
                        .col-12 {{i18nMessageDetailDescription}}
                    .row(v-if="msgDetail.report && msgDetail.report.object")
                        .col-3.embed-responsive.embed-responsive-1by1
                            img.embed-responsive-item(:src="msgDetail.report.object.image")
                        .col {{msgDetail.report.object.name}}

        .row.content.h-100.overflow-auto(ref="messageBox")
            .container.position-relative
                .row
                    .message.my-1.w-100.position-relative(v-for="message in messages" )
                        .message-content.shadow.m-3.py-3(:class="{'message-right':message.speaker.includes('User'),'message-left':message.speaker.includes('Admin')}")
                            .col-12 {{ message.message }}  
                            .col-12.position-relative.clearfix
                                a.d-inline-block.m-1.position-relative(
                                    v-for="image in message.images",
                                    href="#lightbox",
                                    :class="{'float-right':message.speaker.includes('User')}")
                                    //- img.border.image(v-if="message.speaker.includes('Me')" :src="image", @click="()=>{selectedImage = image}")
                                    img.border.lozad.image(:data-src="image", @click="()=>{selectedImage = image}")
                            .col-12.date {{message.create_date}} {{message.create_time}}
                .row(ref="messageBottom")
        .row.input-zone.flex-shrink-1
            .input.col-12.border.py-1
                textarea.w-100.border-0(:placeholder="texyareaPH" :disabled="textareaDisable" v-model="input.message")
                .image-group.d-flex.justify-content-between(v-if="!textareaDisable")
                    .mt-3
                        .btn.btn-outline-secondary(@click="showImagePicker") +
                    input#imageFilePicker(
                        type="file",
                        @change="onFileSelected",
                        accept="image/*",
                        hidden
                    )
                    .text-nowrap.images.pt-3.w-100
                        .btn.ml-1.position-relative(v-for="(imageObj,i) in input.images"  )
                            a.w-100.h-100.position-relative(href="#lightbox")
                                img.w-100.h-100( :src="imageObj.image", @click="()=>{selectedImage = imageObj.image}")
                            .delete-btn.rounded-circle.shadow-lg.text-center(@click="removeImage(i)") x
                    .mt-3
                        button.btn.btn-sm.btn-outline-secondary(:disabled="sending" @click="submit")
                            .spinner-border.spinner-border-sm(v-if="sending")
                            img.submit.bg-transparent(v-else, src="@/assets/icon_send.svg")
    Popup(v-bind="msgDetailPopupConfig",
    :largeSize="true",
    @cancel="msgDetailPopupConfig.popupVisible = false")
        template(v-slot:title) {{i18nMessageDetailSubject}}
        template(v-slot:body)
            div(v-if="i18nMessageDetailDescription") {{i18nMessageDetailDescription}}
            .spinner-border(v-else)
        template(v-slot:footer) 
            button.btn.btn-cancel(@click="goBack") {{$t('button.cancel')}}
            button.btn.btn-edit(@click="()=>{ msgDetailPopupConfig.popupVisible = false }") {{$t('button.appeal')}}
                            
</template>

<script>
// import { loremIpsum } from "lorem-ipsum"
import { getMsgDetail,getAppealList, createAppeal } from '../../api/notification'
import InfiniteLoader from 'vue-infinite-loading'
import Popup from '../Popup.vue'
import lozad from 'lozad'
export default {
    components:{InfiniteLoader, Popup},
    props:{
        report_id:{
            type:String,
            default:undefined
        },
        id:{
            type:String,
            default:undefined
        }
    },
    data() {
        return {
            msgDetailPopupConfig: {
                popupVisible: false,
            },
            caseCode:undefined,
            sending:false,
            selectedImage:undefined,
            messages: [],
            msgDetail:{
                subject: "",
                description: undefined
                },
            input: {
                message:"",
                images: [],
            },
            textareaDisable:false,
            texyareaPH: "請輸入申訴內容"
        }
    },
    mounted() {
    },
    methods: {
        goBack(){
            this.msgDetailPopupConfig.popupVisible = false
            this.$router.go(-1)
        },
        getMessageDetail(){
            getMsgDetail(this.id).then(res=>{
                        this.msgDetail = res.data
                        this.msgDetail.report_id = this.report_id
            })
        },
        getAppealMessages(){
            getAppealList(this.report_id).then(res=>{
                this.messages = res.data.messages
                if (this.messages.length && this.messages[this.messages.length - 1].speaker.includes("User")){
                    this.textareaDisable = true
                    this.texyareaPH = "請靜待客服人員回覆後再傳送新訊息"
                }
                this.$nextTick(()=>{
                    let el = this.$refs.messageBox
                    el.scrollTo(0,el.scrollHeight)
                    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
                    observer.observe();
                })
                console.log(this.messages)
                if (this.messages.length == 0){
                    this.msgDetailPopupConfig.popupVisible = true
                         console.log(this.msgDetailPopupConfig.popupVisible)
                    this.getMessageDetail()
                }else{
                    console.log("")
                }
            })
        },
        scrollToMessageBottom(){
            let el = this.$refs.messageBox
            el.scrollTo({
                top: el.scrollHeight,
                left: 0,
                behavior: 'smooth'
                })
        },

        submit(){
            this.$confirm("發送訊息後，在客服人員回應前無法在發送下一則訊息。你確定要發送嗎？").then(() => {
                this.sending = true 
                
                let payload = new FormData()
                payload.append('report_id',this.report_id)
                this.input.images.forEach(
                    element => payload.append('images[]',element.file)
                )
                payload.append('description',this.input.message.trim())
                createAppeal(payload).then(res=>{
                    console.log(res.data)
                    this.getAppealMessages()
                })
                setTimeout(() => {
                    this.sending = false
                    this.scrollToMessageBottom()
                    this.input.message = ""
                    this.input.images = []
                }, 1000);
            });
        },
        removeImage(index){
            this.input.images.splice(index,1)
        },
        showImagePicker() {
            document.getElementById("imageFilePicker").click()
        },
        async onFileSelected(evt) {
            let self = this
            evt.target.files.forEach((file) => {
                let f = file
                // Only process image files.
                if (!f || !f.type.match("image.*")) {
                    return
                }
                let newImage = { file, image: undefined }
                var reader = new FileReader()

                // Closure to capture the file information.
                reader.onload = (function () {
                    return function (e) {
                        newImage.image = e.target.result
                        self.input.images.push(newImage)
                    }
                })(f)
                // Read in the image file as a data URL.
                reader.readAsDataURL(f)
            })
        },
    },
    computed:{
        isChinese(){
            return this.$store.getters.isLanguageChinese
        },
        i18nMessageDetailSubject(){
            if(this.isChinese){
                return this.msgDetail.subject_tw
            }else{
                return this.msgDetail.subject_en

            }
        },
        i18nMessageDetailDescription(){
            if(this.isChinese){
                return this.msgDetail.description_tw
            }else{
                return this.msgDetail.description_en

            }
        }
    },
    watch:{
        id:{
            deep:true,
            immediate:true,
            handler(){
                this.getMessageDetail()
                this.getAppealMessages()
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.appeal
    padding-top: 10px
$message-image-size: 50px
.header
    border-radius: 5px 5px 0px 0px
    background-color: #FF6B00
    // color: #FFF
    padding: 0px 10px 10px 10px
.container
    max-width: 600px
    height: 550px
.message
    .image
        width: $message-image-size
        height: $message-image-size
    .images
        height: $message-image-size
    .date
        font-size: 0.7rem
        color: #aaa
.message-content
    border-radius: 20px 20px 20px 0px
    background-color: #ffdbc1
    max-width: 80%
    &.message-right
        border-radius: 20px 20px 0px 20px
        float: right
    &.message-left
        border-radius: 20px 20px 0px 20px
        float: left
textarea
    min-height: 100px
    resize: none
    &:focus
        outline: none !important
.input-zone
    min-height: 190px
.input
    .btn
        display: inline-flex
        justify-content: center
        align-items: center
        padding: 0
        width: 40px
        height: 40px
    img
        background: gray
.lightbox 
    cursor:  zoom-out
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    background-color: #00000066
    z-index: -1
    opacity: 0
   
    img
        object-fit: contain
.lightbox-item
    &:hover
        cursor: zoom-in
.lightbox:target 
  z-index: 9999
  opacity: 1
  transition: 0.3s
.image-group
    height: 70px
    
.images
    overflow-x: scroll
    overflow-y: visible
    &::-webkit-scrollbar 
        display: none
.delete-btn
    line-height: 20px
    width: 20px
    height: 20px
    position: absolute
    right: 0
    bottom: 75%
    background: white
.submit
    stroke: gray
    fill: gray
</style>