<template lang="pug">
  .card.hover_pointer
    .card-body(@click="onclick")
      .embed-responsive.embed-responsive-1by1
        img.card-img.embed-responsive-item(:src="item.image_url")
      h5.card-title {{item.title}}
</template>

<script>
export default {
    props:["item"],
    methods:{
      onclick(){
        if(this.item.onclick){
          this.item.onclick()
        }
      }
    }
}
</script>

<style lang="sass" scoped>
.card-img
  object-fit: contain
</style>