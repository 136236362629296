export function isNumber(string) {

    var regexp = /^[0-9]+$/;
    
    return regexp.test(string);
    
    }
export function isCompanyId(string) {

    var regexp = /^[0-9]{8}$/;
    
    return regexp.test(string);
    
    } 
export function isCertificated(string) {

    var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;
    
    return regexp.test(string);
    
    }
export function isInvoiceＣarrier(string) {

    var regexp = /^\/[0-9a-zA-Z+-.]{7}$/;
    
    return regexp.test(string);
    
    }
    
    