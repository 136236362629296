<template>
  <div>
    <div class="content container col-7">
      <div id="enter-info">
        <div class="title title-text text-center">
          <div class="col-12">恭喜您完成Email驗證</div>
          <div class="col-12">請輸入帳號資料完成註冊</div>
        </div>
        <div class="block">

         <div class="error" v-if="onSubmit && !$v.nickname.required">* 必填</div>
          <input
            id="reigster-username"
            class="cubiio-input-large"
            type="text"
            v-model="nickname"
            placeholder="使用者名稱"
            required
          />

         <div class="error" v-if="onSubmit && !$v.password.required">* 必填</div>
          <input
            id="register-password"
            class="cubiio-input-large"
            type="password"
            v-model="password"
            placeholder="使用者密碼"
            required
          />

         <div class="error" v-if="onSubmit && !$v.confirm.required">* 必填</div>
          <input
            id="register-validation"
            class="cubiio-input-large"
            type="password"
            v-model="confirm"
            placeholder="驗證密碼"
            required
          />
          <div class="cubiio-button-large btn" @click="submit">{{submitText}}</div>
        </div>
      </div>
      <div id="mail-outdated" hidden>
        <div class="title title-text text-center">
          <div class="col-12">驗證信已過期</div>
        </div>
        <div class="cubiio-button-large" onclick="resendValidationMail()">
          重新發送驗證信(60)
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { required, minLength,sameAs } from 'vuelidate/lib/validators'
export default {
    data() {
        return {
            submitText:"完成註冊並登入",
            onSubmit:false,
            pending:false,
            code:"",
            email:"",
            nickname:"",
            password:"",
            confirm:""
        }
    },
    mounted() {
        let email = this.$route.query.email
        let code = this.$route.query.code
        if(!email || !code){
            this.$router.push('/')
            return
        }
        this.code = code
        this.email = email
    },
    methods: {
        submit(){
            this.pending = true
            this.onSubmit = true
            this.$api.nicknameExistCheck(this.nickname).then(res=>{
                console.log(res)
                if(res.data.result == false){
                    this.$api.register(this.nickname,this.email,this.password,this.code).then(res=>{
                        console.log(res)
                        this.pending = false
                    })
                }else{
                    this.pending = false
                }
            })
           
        }
    },

    validations: {
        nickname:{
            required
        },
        password:{
            required,
            minLength: minLength(8)
        },
        confirm:{
            required,
            sameAsPassword:sameAs('password')
        }
    }
}
</script>
<style scoped>
    .content {
  width: 100%;
  min-height: 100%;
  padding: 56px auto
}

.title {
  width: 100%;
  margin: 56px auto 44px auto;
}

.title-text {
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 0.15em;
  color: black;
}

.title + .block {
  background-color: #23232C;
  border-radius: 20px;
  padding: 63px 112px
}

#cover {
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 20, 0.9);
  overflow: scroll;
  z-index: 10;
  position: absolute;
  top: 0;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#cover::-webkit-scrollbar {
  display: none;
}

.logo-img {
  margin: 108px auto 22px auto;
}

.introduction {
  color: #eeeeee;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-top: 22px;
}

#cover .content {
  min-height: 440px;
  margin: 48px auto 200px auto;
  background: #FFFFFF;
  border-radius: 15px;
  padding: 45px
}

.main > div {
  min-height: 440px;
  background-color: white;
  border-radius: 15px;
  padding: 0 45px !important;
}

.header {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: 0.15em;
  text-align: center;
}

.baseline {
  width: 100%;
  margin-top: 30px;
  height: 0;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.button-close {
  position: relative;
  right: 0;
  border-radius: 5px;
}

.button-close:hover {
  background-color: lightgray;
  cursor: pointer;
}

.button-close img {
  width: 36.67px;
  height: 36.67px;
}

.warning {
  margin: 36px auto 18px auto;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: red
}

.emailSent-text {
  height: 24px;
  margin: 60px auto;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
}

#sendMail-success {
  margin-top: 100px;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  line-height: 43px;
  letter-spacing: 0.05em;
  color: rgba(35, 35, 44, 0.9);
}

.mailOutdated {
  height: 24px;
  margin: 60px auto;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: red
}
  
</style>