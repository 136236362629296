import PlanTypeEnum from './PlanTypeEnum'
export default class MembershipPlan {
    constructor(plan={}){
        this.membership = PlanTypeEnum.brozen
        this.plan_id = "3E344CDF-9F93-4A55-A4F8-C698610F0E7F"
        this.max_upload = 50
        this.max_download = 3
        this.cost = 0
        this._parseInitPlan(plan)
    }
    _parseInitPlan(plan={}){
        let {membership,plan_id,max_upload,max_download,cost} = plan
        this.membership = membership != undefined ? membership: this.membership
        this.plan_id = plan_id != undefined ? plan_id : this.plan_id
        this.max_upload = max_upload != undefined ? max_upload : this.max_upload
        this.max_download = max_download != undefined ? max_download : this.max_download
        this.cost = cost != undefined ? cost: this.cost
    }

}