<template >
    <div class="container pt-3">
   <div class="row ">
      <!-- 左側會員資料 -->
      <div class="col-12 col-md-3  d-flex justify-content-between flex-column align-items-center p-3 p-md-0">
        <div class="member w-100 h-100 p-3">
        <!-- <div class="sub">Photo maximum size : 1mb</div> -->
        <!-- 會員照片 -->
        <div class="square">
          <div class="inner rounded-circle  border overflow-hidden d-flex justify-content-center align-items-center">
             <img class="mw-100 mh-100"  :src="profile.photolink"  />
              <!-- <img class="w-100 h-100" src="@/assets/crown.png"/> -->
          </div>
            <img class="mask bg-secondary rounded-circle p-3 hover_pointer" src="@/assets/camera.svg" alt="Member photo" @click="editUserPhoto"/>
        </div>
        <!-- 會員資料 -->
        <div v-if="loading" class="text-center container">
          <div class="spinner-border"></div>
        </div>
        <div v-else class="text-center container">
          <!-- <div class="mt-3 font-weight-bold">  {{user.nickname}} </div> -->
          <div class="mt-3 font-weight-bold membership">
            <img  class="mr-1" v-if="profile.membership=='Gold'" src="@/assets/golden.png">
            <img  class="mr-1" v-if="profile.membership=='Silver'" src="@/assets/silver.png">
             {{membershipName}}
              </div>
          <div class="mt-3 font-weight-bold row align-items-center"> 
              <div class="col-6 text-right">
                <img src="@/assets/download.svg" alt=""/>
              </div>
              <div class="col-6 text-left">
                  {{profile.download.used+" / "+(profile.download.total==0?"--":profile.download.total)}} 
              </div>
          </div>
          <div class="mt-3 font-weight-bold row align-items-center"> 

              <div class="col-6 text-right">
              <img src="@/assets/upload.svg" alt=""/>
              </div>

              <div class="col-6 text-left">
              {{profile.upload.used+" / "+profile.upload.total}} 
              </div>
          </div>
          <!-- <div> 下一次續訂日期 </div>
          <div> 2021/02/05 </div> -->
        </div>
        <div class="text-muted mt-5 mb-3 text-center"> 
              <a href="#" alt="關閉帳號" @click="deleteSelf">{{$t('button.deleteAccount')}}</a>
            </div>
      </div>
      </div>
      <!-- 右側個人編輯資料 -->
      <div class="col-12 col-md-9 prof-edit  mt-3 mt-md-0">
        <div class="row">
          <div class="col-6 col-md-4">
            <div v-if="isMyProfile && !editMode" class="w-100 btn btn-edit" @click="switchMode"> {{$t('button.editProfile')}}</div>
          </div>
        </div>
        <div class="row form-edit">
          <div class="col-md-4">
            {{$t('label.username')}} <span class="text-username-ocu">* {{$t('error.username_taken')}}</span>
            <div>
              <!-- 使用者名稱 -->
              <div>
                <input type="text" v-model.lazy="profile.nickname" placeholder="User name" aria-label="User name" required :disabled="!editMode || isContractor"/>
              </div>
              <!-- 性別 -->
              <select v-model="profile.extra_info.gender" class="form-select" id="gender" :disabled="!editMode">
                <option value="0">{{getGenderString(0)}}</option>
                <option value="1">{{getGenderString(1)}}</option>
                <option value="2">{{getGenderString(2)}}</option>
                <option value="3">{{getGenderString(3)}}</option>
              </select>
              <!-- 地區 -->
              <select class="form-select" v-model="profile.extra_info.nation" id="nationality" :disabled="!editMode">
                <option :value="0">{{$t('label.nationality')}}</option>
                <option v-for="c in nationList" :value="c.name" :key="c.name">{{c.name}}</option>
                <!-- <option value="other">其他2</option> -->
              </select>
              <!-- 密碼 -->
              <div class="input d-flex justify-content-between align-items-center p-0" >
                <!-- <div >Password</div> -->
                <button class="w-100 h-100 btn btn-edit m-0 text-left"  @click="sendResetPasswordEmail">{{message}} <img src="@/assets/gear.png" alt=""></button>
              </div>
              <!-- 出生年 -->
              {{$t('label.birthday')}}
              <select v-model="profile.extra_info.birth_year" class="form-select" id="birth-year" :disabled="!editMode">
                <option :value="0" :key="0">{{$t('label.year')}}</option>
                <option v-for="(year) in  years()" :key="year" :value="year">{{ year }}</option>
              </select>
              <!-- 出生月日 -->
              <div class="birth-md">
                <select v-model="profile.extra_info.birth_month" class="form-select" id="birth-month" :disabled="!editMode">
                  <option :value="0" :key="0">{{$t('label.month')}}</option>
                  <option v-for="i in 12" :value="i" :key="'m'+i">{{i}}</option>
                </select>
                <select v-model="profile.extra_info.birth_day" class="form-select" id="birth-day" :disabled="!editMode">
                  <option :value="0" :key="0">{{$t('label.day')}}</option>
                  <option v-for="i in 31" :value="i" :key="'b'+i">{{i}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md">
            {{$t('label.description')}} <span class="text-limit">{{letters}}</span>
            <div>
              <textarea class="form-control" @change="invalid == $v.$invalid" rows="5" aria-label="簡介" maxlength="500" v-model.lazy="profile.description" :disabled="!editMode"></textarea>
            </div>
            <div class="input-group mt-2">
              <div type="email" class="form-control h-100 overflow-auto" placeholder="Email" aria-label="Email">{{profile.email}}</div>
              <span v-if="profile.share_email" class="text-email-open">{{$t('utility.public')}}</span>
              <span v-else class="text-email">{{$t('utility.private')}}</span>
              <button class="btn m-0" @click="profile.share_email = !profile.share_email"  :disabled="!editMode">
                  <img src="@/assets/global.png" :class="[!profile.share_email?'btn-email':'btn-email-open' ]"  >
              </button>
               



            </div>
          </div>
        </div>

          <div   v-if="isMyProfile && editMode" class="row btn-part justify-content-end">
              <!-- <div class="">{{message}}</div> -->
              <button class="col-md-4 col-12 btn btn-cancel m-1 " @click="cancel" :disabled="pending">{{$t('button.cancel')}}</button>
              <button class="col-md-4 col-12 btn btn-edit m-1" @click="save" :disabled="pending || invalid">{{$t('button.submit')}}</button>

            </div>
      </div>
    </div>
  </div>
    

</template>

<script>
import {  maxLength} from "vuelidate/lib/validators";
import genderTool from './utilities/gender'
import imageTool from './utilities/image'
import MembershipEnum from '../models/MembershipEnum'
export default {
  components:{
  },
  props:["id"],
  data(){
    return{
      loading:false,
      isContractor : this.$store.getters.isContractUser,
      nationList:[],
      invalid:false,
      message:this.$t('status.changePassword'),
      pending:false,
      availableYears:100,
      isMyProfile:false,
      editMode:false,
      profile:{
        photolink:"",
        membership:"",
        share_email:false,
        nickname:"",
        email:"",
        description:"",
        extra_info:{
          birth_year:0,
          birth_month:0,
          birth_day:0,
          nation:0,
          gender:0,
        },
        upload:{
          total:0,
          used:0
        },
        download:{
          total:0,
          used:0
        }
      }
    }
  },
  beforeMount() {
    let token = this.$store.state.user.token

    this.getUserInfo()
    this.$api.getNationList(token).then(res=>{
      if(res.data){
        this.nationList = res.data
      }
      let taiwan
      for(let i=0;i<this.nationList.length;i++){
        if(this.nationList[i].name == "Taiwan"){
          taiwan = this.nationList.splice(i,1)[0]
          // console.log(taiwan)
          break
        }
      }
      if(taiwan){
        this.nationList.unshift(taiwan)
      }
    })
  },
  mounted(){
    if(this.id == this.user.id){
      this.isMyProfile = true
    }
    this.resetUser()
    // console.log(this.user)
  },
    methods: {
      getUserInfo(){
        this.loading = true
        let token = this.$store.state.user.token
        this.$api.loginByToken(token).then(res=>{
          if(res.error){
              //登出
              // console.log("login by token error",res.error)
              window.localStorage.removeItem("cub_user")
              this.$store.commit("updateUser",undefined)
              return
          }else{
              let data = res.data
              // console.log("login by token token",token)
              // console.log("login by token data",data)
              this.$appBridge.login(token)
              this.$store.commit("updateUser",{
                user:data,
                token:token
              })
              this.resetUser()
            }
        }).finally(()=>{
          this.loading = false
        })
      },
      getGenderString(code){
        return genderTool.codeToGenderString(code)
      },
      sendResetPasswordEmail(){
        this.$confirm(this.$t('article.changePasswordConfirm')).then(()=>{
            this.$api.forgetPassword(this.profile.email).then(()=>{
            this.message=this.$t('status.emailSent')
          })
        }).catch(()=>{
          return
        })
        
      },
       editUserPhoto:function(){
        let token = this.$store.state.user.token
        if(!token){
          return
        }
        var input = document.createElement('input');
        input.type = 'file';
        input.accept="image/*"

        input.onchange = async e => { 
          var file = e.target.files[0]; 
        let compressedImage = await imageTool.resizeImage({ file: file, maxSize: 1000 })
        // this.saveBase64AsFile(compressedImage,"test.jpg")
          // console.log(compressedImage)
         this.$api.updateUserPhoto(token,compressedImage).then(res=>{
           if(res.data.error){
            //  console.log(res.data.error)
             this.$alert(this.$t('imageTooLarge'))
             return
           }
           this.getUserInfo()
            }).catch(()=>{
             this.$alert(this.$t('imageTooLarge'))
            })
        }
        input.click();
        
      },
    //  saveBase64AsFile(blob, fileName) {
    //      var reader = new FileReader();

    // reader.onloadend = function () {    
    //     var base64 = reader.result ;
    //     var link = document.createElement("a");

    //     document.body.appendChild(link); // for Firefox

    //     link.setAttribute("href", base64);
    //     link.setAttribute("download", fileName);
    //     link.click();
    // };

    // reader.readAsDataURL(blob);
    //   },
      years() {
        var adultYears = []
        for ( var eachYear = 0  ; eachYear <= this.availableYears  ; eachYear++ ) {
          var today = new Date()
          var thisYear = today.getFullYear()
          adultYears.push(thisYear-5-eachYear)

        }
        // console.log(adultYears)
        return adultYears
      },
      save(){
        this.pending = true
        let token = this.$store.state.user.token
        if(!token){
          return
        }

        if(this.profile.nickname == this.$store.state.user.user.nickname){
          this.updateUser(token)
        }else{
          this.$api.nicknameExistCheck(this.profile.nickname).then(res=>{
            if(res.data && res.data.result == false){
            this.updateUser(token)
            
            }else{
              this.$alert(this.$t('error.username_taken'))
              this.editMode = true
              this.pending = false
            }
          }).catch((e)=> console.log(e))
        }

      },
      updateUser(token){
        let gender = parseInt(this.profile.extra_info.gender) || 0
        let update={
              share_email:this.profile.share_email,
              nickname:this.profile.nickname,
              description:this.profile.description || "",
              birth_year : this.profile.extra_info.birth_year,
              birth_month :this.profile.extra_info.birth_month,
              birth_day :this.profile.extra_info.birth_day,
              gender : gender
          }
          if(this.profile.extra_info.nation){
            update.nation = this.profile.extra_info.nation
          }
          this.$api.updateUser(token,update).finally(()=>{ 
            this.getUserInfo()
            this.editMode = false
            this.pending = false
          });
      },
      cancel(){
        this.editMode = false
        this.resetUser()
      },
      resetUser(){
        for(let key in this.profile){
          if(this.user[key]){
          this.profile[key] = this.user[key]
          }
        }
      },
        switchMode(){
          this.editMode = !this.editMode
        },
        deleteSelf(){
           this.$confirm(this.$t('article.deleteAccountConfirm')).then(()=>{
            this.$api.killSelf(this.$store.state.user.token).then(res=>{
                if(!res.data.error){

                this.$store.commit("updateUser",undefined)
                window.localStorage.removeItem("cub_user")
                this.$router.push("/")
                }
                //刪除帳戶後這邊
                this.$appBridge.logout()
                this.$store.commit("updateUser",undefined)
                window.localStorage.removeItem("cub_user")
                // this.$router.push("/")
                this.$router.push('/').catch(()=>{
                this.$router.go(0)
                })
            })
        }).catch(()=>{
          return
        })
            
        },
        getDaysInMonth (month,year) {
            // Here January is 1 based
            //Day 0 is the last day in the previous month
            // if 
            return new Date(year, month, 0).getDate();
            // Here January is 0 based
            // return new Date(year, month+1, 0).getDate();
      }
    },  
    computed:{
        letters(){
          return this.profile.description.length +"/500"
        },
        membershipName(){
          console.log(this.profile.membership)
            switch(this.user.membership.toLowerCase()){
              case MembershipEnum.gold:
                return this.$t('label.golden')
                // return "金卡會員"
                case MembershipEnum.silver:
                return this.$t('label.silver')
                // return "銀卡會員"
                default:
                return this.$t('label.basic')
                // return "普通會員"
            }
        },
       user(){
            return this.$store.state.user.user
        }
    }, 
    validations: {
      profile: {
        description: {
          maxLength: maxLength(500),
        },
      }
    }
}
</script>

<style scoped>
.sub{
  font-size:.6em
}
.nav-opt {
  width: 100%;
  height: 60px;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  top: 0;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 60px;

}
a{
  color:inherit;
}
.member {
  position: relative;
  top:30px;
  /* height: 75vh; */
  border: 1px solid #000000;
  border-radius: 10px;
  padding-top: 30px;
  /* min-height: 500px; */
}

/* img {
  position: absolute;
  left: calc(50% - 80px);
} */

/* .member-info {
  position: absolute;
  left: calc(55% - 80px);
  top: calc(55% - 80px);
  text-align: center;
  line-height: 32px;
  font-size: 14px;
} */

.close-acc {
  margin-top: 70%;
}

.prof-edit{
  top:30px;
}
.text-username-ocu{
  font-size:0.5rem;
  color:#EB5757;

  visibility: hidden;
}
.form-edit{
  position: relative;
  top:10px;
  left:15px;
  width:100%;
  /* height: 70%; */
  padding: 5%;
  border-radius: 10px;
  background-color: #f7f7f7;
}
input,.input,select{
  background-color: white;
  width:100%;
  margin-top: 5px;
  height: 38px;
  font-size: 1rem;
  border: 1px solid rgba(118, 118, 118, 0.3);
  /* border-color: rgba(118, 118, 118, 0.3); */
}
input,.input{
  padding-left: 5px;
}
.input{
  color:rgb(128, 128, 128);
}
.input>button{
  padding-left: 5px;
  font-size: 1rem !important;
}
.input >button> img{
  float: right;
  height: 25px;
  width: 25px;
}
input:disabled,select:disabled,textarea:disabled,.input:disabled{
  opacity: 0.7;
  /* background-color:rgba(170, 170, 170,0.3); */
  background-color: white;
  border-color: rgba(118, 118, 118, 0.5);
}
span{
  margin-top: 5px;
}
button{
  margin-top:5px;
}

/* select{
  width:100%;
  height:38px;
  margin-top:5px;
} */
.text-limit{
  font-size:0.5rem;
  color:#C4C4C4;
}
/* 公開email */
.btn-email-open{
  background-color: #FF6B00;
  color:#FFF;
  position: relative;
}
.text-email-open{
  position: absolute;
  top:5px;
  right: 45px;
  /* left:-30px; */
  font-size:0.5rem;
  color:#C4C4C4;
  visibility:visible;
}

/* 不公開email */
.btn-email{
  background-color: #C4C4C4;
  color:#DADADA;}

.text-email{
    position: absolute;
    top:5px;
    right: 45px;
    /* left:-42px; */
    font-size:0.5rem;
    color:#C4C4C4;

    visibility:visible;
  }
.birth-md{
  display: flex;
}
#birth-month{
  width: 49%;
  dispay:flex;
}
#birth-day{
  width: 49%;
  dispay:flex;
  margin-left:2%;
}
.btn-part{
  margin-top:15px;
  padding:15px;
}
.input-group{
  height: 40px;
}
.input-group > input{
  height: 40px;

}
.input-group > button{
  padding: 0;
  /* margin-top: 5px; */
  height: 40px;
  width: 40px;
}
.input-group > button >img{
  width: 100%;
  height: 100%;
}
.mask{
  position: absolute;
  /* top: 0;
  left: 0; */
  right: 0;
  bottom: 0;
  opacity: .3;
}
.photo{
  max-width: 100%;
  max-height: 100%;
}
.membership img{
  width: 36px;
  height: 36px;
}
</style>