import InvoiceInfo from './InvoiceInfo'

export default class PaymentData {
    // #prime = ""
    #amount = 999
    #currency = ""
    #plan_id = ""
    #coupon_token= null
    #language = 1
    #invoice_info = new InvoiceInfo()
    #card = {}
    constructor() {
    }
    set language(value){this.#language = value}
    // set prime(value){this.#prime = value}
    set amount(value){this.#amount = value}
    set currency(value){this.#currency = value}
    set plan_id(value){this.#plan_id = value}
    set coupon_token(value){this.#coupon_token = value}
    set invoice_info(value){this.#invoice_info = value}
    set card(value){this.#card = value}
    
    get card(){return this.#card}
    get language(){return this.#language}
    // get prime(){return this.#prime}
    get amount(){return this.#amount}
    get currency(){return this.#currency}
    get plan_id(){return this.#plan_id}
    get coupon_token(){return this.#coupon_token}
    get invoice_info(){return this.#invoice_info}
    toJSON() {
        return {
            language:this.language,
            // prime: this.prime,
            amount: this.amount,
            currency: this.currency,
            plan_id: this.plan_id,
            coupon_token: this.coupon_token,
            card: this.card,
            invoice_info : this.invoice_info.toJSON()
        }
    }
}
