import instance from './instance'
// export function 

export function getVocherDescription(code){
    return instance
    .get(`/web/voucher/description/${code}`)
  }
export function getCouponDescription(code){
    return instance
    .get(`/coupon/discount/${code}`)
  }
export function  getRedeemedVoucher(){
    return instance
    .get(`/web/user/voucher/`)
  }