import ApiInstance from './api/instance'
import Vuex from 'vuex'
import Vue from 'vue'
import LanguageEnum from './models/LanguageEnum'
 export default function(){
     return new Vuex.Store({
    state: {
      lang: null , //語系
      user:undefined,
      sortBy:"c",
      searchTerm:undefined,
      filterOptions:{
        machine_type:{
            name:'雷射',
            options:{
                LC35:{name:'LC35',selected:true,value:"LC35"},
                LC50:{name:'LC50',selected:true,value:"LC50"},
            },
        },
        range:{
            name:"官方",
             options:{
                official:{name:'官方',selected:true,value:"official"},
                user:{name:'使用者',selected:true,value:"user"},
            }
        },
        membership:{
            name:'會員方案',
            options:{
                Gold:{name:'金卡',selected:true,value:"Gold"},
                Silver:{name:'銀卡',selected:true,value:"Silver"},
                Bronze:{name:'普通',selected:true,value:"Bronze"},
            },
        },
        searchFrom:{
            name:'搜尋',
            options:{
                Project:{name:'專案名稱',selected:true,value:"Project"},
                Author:{name:'作者名稱',selected:true,value:"Author"},
            },
        }

    },
      isAppMode:false,
      materialList:[],
      fileList:[],
      groupList:[]
    },
    mutations: {
      setLang (state, value) {
        state.lang = value;
      },
      updateUserPhoto(state,link){
          state.user.photoLink = link
      },
      updateFilter(state,filters){
        if(filters.materials){
          Vue.set(state, 'materialList', [...filters.materials]);
        }
        state.sortBy = filters.sortBy || "c"
        state.searchTerm = filters.searchTerm 
        if(filters.options){
          Vue.set(state, 'filterOptions',filters.options);
        }
      },
      updateFilterOptions (state,filterOptions) {
        state.filterOptions == filterOptions
      },
      updateSortBy (state,sortBy) {
        state.sortBy == sortBy
      },
      updateMaterialList (state,newList) {
        // console.log("updateMaterialList ",newList)
        Vue.set(state, 'materialList', [...newList].map(item=>{
          if(state.lang == LanguageEnum.zh.key){
            item.displayName = item.listing.traditional_chinese
          }else{
            item.displayName = item.listing.english
          }
          return item
      }));
      },
      updateFileList (state,newList) {
        state.fileList == newList
      },
      updateGroupList (state,newList) {
        state.groupList == newList
      },
      updateUser(state,newUser){
        window.localStorage.setItem("cub_user",JSON.stringify(newUser))
        state.user = newUser
        let token = newUser && newUser.token || ""
        // set api instance token
        ApiInstance.defaults.headers.common['Authorization'] = "Bearer "+token
      },
      updateIsAppMode(state,newValue){
        state.isAppMode = newValue
      }
    },
    getters:{
      isContractUser:state=>{
        if (state.user.user.membership == "Contract"){return true}
        else{return false}
      },
      userToken:state=>{
        if (!state.user){return undefined}
        return state.user.token
      },
      currentLang:state=>{
        if(state.lang == LanguageEnum.zh.key){
          return LanguageEnum.zh
        }else{
          return LanguageEnum.en
        }
      },
      isLanguageChinese:state=>{
        return state.lang == LanguageEnum.zh.key
      }
    },
    actions:{
      setLang (store, value) {
        store.commit("setLang",value)
        store.commit('updateMaterialList',store.state.materialList)
      }
    }
  })
}