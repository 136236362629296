export default class ProjectDownloadPermissionError{
    constructor(errorCode){
        this._errorCode = errorCode
        this._errorMessage = this.parseErrorMessageInLocalizedStruct(errorCode)
    }
    get errorCode(){
        return this._errorCode
    }
    get errorMessage(){
        return this._errorMessage
    }
    parseErrorMessageInLocalizedStruct(errorCode){
        errorCode = errorCode+""
        let result = {
            localizedMainMessage:'article.downloadMenbershipWarning',
            localizedArg:{
                membership:undefined
            }
        }
        switch(errorCode){
            case '100':
                result.localizedMainMessage='article.downloadMenbershipWarning'
                result.localizedArg.membership = "label.silver"
                break
            case '101':
            case '102':
                result.localizedMainMessage='article.downloadMenbershipWarning'
                result.localizedArg.membership = "label.golden"
                break
            case '110':
                result.localizedMainMessage='article.downloadLimitationReached'
                break
            case 400:
            default:
                result.localizedMainMessage='article.otherError'
        }
        return result
    }
}