import axios from "axios";
import https from 'https'
let protocol =  window.location.protocol
let host = window.location.host
// console.log("current protocol "+ protocol );
// console.log("current port "+ port );
// console.log("current process.env.NODE_ENV "+ process.env.NODE_ENV );
// const baseURL = process.env.NODE_ENV === "development" ? "/api" : "https://211.75.169.51:" + port + "/v0"
const baseURL = process.env.NODE_ENV === "development" ? "/api" :  protocol+"//" + host

// const baseURL = protocol+"//" + host + "/v0"

var instance = axios.create({
  baseURL,
  timeout: 15 * 1000,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  changeOrigin: true,
});
export default instance