<template lang="pug">
#sub
  .container-fluid
    .row.pt-5
      .col-lg-8.col-md-10.col-12.m-auto.pt-3
        SubscriptionStepNavigator(currentStepKey="3")
        .payment
          .title.row.align-items-end
            .mr-2 購買方案
          .block
            .row.p-3.border-bottom
              .col-3 Item
              .col-9 {{ purchaseItemKey }}
          .block
            .row.p-3.border-bottom
              .col-3 Price
              .col-9 
                .price(v-if="purchasedItem.discountedPrice")
                  span.small.deleted-text ${{ purchasedItem.price }}
                  span.text-danger.ml-3 ${{ purchasedItem.discountedPrice }}
                div(v-else) ${{ purchasedItem.price }}
          .block.mb-3
            .row.p-3
              .col-sm-3.col-12 Coupon
              .col-sm-9.col-12
                .coupon_field
                  input(v-model.lazy="coupon.code" @change="useCoupon")
                  .btn.text-muted(
                    v-if="coupon.status == 'used'",
                    @click="cancelCoupon"
                  ) 取消
                  .spinner-border.text-warning.ml-1(
                    v-else-if="coupon.status == 'checking'"
                  )
                    span.sr-only Loading...
                .small.text-muted {{coupon.description}}
          .title.row.align-items-end.mt-3
            .mr-2 付款資訊
          .block.py-2
            .col-12 信用卡卡號 (支援的卡別)
              #card-number.tpfield
              #card-expiration-date.tpfield
              #card-ccv.tpfield
          .title.row.align-items-end
            .mr-2 發票資訊
            .subtext 會寄到您認證的信箱
          .block.invoice
            .row.p-3.border-bottom
              input.my-auto(id="notTaiwanese_input" type="checkbox", v-model="notTaiwanese")
              label.col.my-auto(for='notTaiwanese_input') 我不是台灣人
            .invoiceForm(v-if="!notTaiwanese")
              .p-3.border-bottom
                .row.mx-0.mb-2
                  .col-12
                    input.my-auto(
                        id="invoice_person",
                      type="radio",
                      name="invoice",
                      :value="invoiceTypeEnum.person",
                      v-model="invoiceType"
                    )
                    label(for="invoice_person") 個人發票
                  .col-12.py-1.collapse(
                    :class="{ show: invoiceType == invoiceTypeEnum.person }"
                  )
                    
                    .row.m-0
                        .col-12
                            input.my-auto(type="radio" id="carrier_member" :value="carrierTypeEnum.member" v-model="carrier.type")
                            label(for="carrier_member") 會員載具
                    .row.m-0
                        .col-12
                            input.my-auto(type="radio" id="carrier_person" :value="carrierTypeEnum.person" v-model="carrier.type")
                            label(for="carrier_person") 手機載具
                            span.error(v-if="$v.$dirty && $v.carrier.mobileCode.$invalid && !$v.carrier.mobileCode.required") Code is required
                            span.error(v-else-if="$v.$dirty && $v.carrier.mobileCode.$invalid && !$v.carrier.mobileCode.isInvoiceＣarrier") Invalid code
                            input.ml-2.my-auto(v-if="carrier.type == carrierTypeEnum.person" type="text" v-model="carrier.mobileCode")
                    .row.m-0
                        .col-12
                            input.my-auto(type="radio" id="carrier_citizen_digital_certificate" :value="carrierTypeEnum.citizen_digital_certificate" v-model="carrier.type")
                            label(for="carrier_citizen_digital_certificate") 自然人載具
                            span.error(v-if="$v.$dirty && $v.carrier.code.$invalid && !$v.carrier.code.required") Code is required
                            span.error(v-else-if="$v.$dirty && $v.carrier.code.$invalid && !$v.carrier.code.isInvoiceＣarrier") Invalid code
                            input.ml-2.my-auto(v-if="carrier.type == carrierTypeEnum.citizen_digital_certificate" type="text" v-model="carrier.code")
              .p-3.border-bottom
                .row.mx-0.mb-2
                  .col
                    input.my-auto(
                        id="invoice_donation",
                      type="radio",
                      name="invoice",
                      :value="invoiceTypeEnum.donation",
                      v-model="invoiceType"
                    )
                    label(for="invoice_donation") 捐贈發票

                .py-1.collapse(
                  :class="{ show: invoiceType == invoiceTypeEnum.donation }"
                )
                  .row.m-0.px-3
                    .col
                      input.my-auto(type="radio", id="donation_default" :value="donationTypeEnum.default" v-model="donation.picked")
                      select.mx-3(v-model="donation.code" :disabled="donation.picked != donationTypeEnum.default")
                        option(v-for="org in donation.organizations" :value="org.code") {{org.name}}
                  .row.m-0.px-3
                    .col
                        input.my-auto(type="radio", id="donation_others" :value="donationTypeEnum.others" v-model="donation.picked") 
                        label.ml-3(for="donation_others") 其他
                        .position-relative.w-100
                            input.w-100(v-model="loveCodeSearchTerm" v-if="donation.picked == donationTypeEnum.others")
                            .dropdown-menu.dropdown-menu-left(:class="{show:(loveCodeSearchTerm&&filteredLoveCodes.length)}")
                                .dropdown-item(v-for="item in filteredLoveCodes" @click="loveCodeSelected(item)") {{item.code+"/"+item.name}}
                    //-   input.mx-3(v-if="donation.picked == donationTypeEnum.others" type="text" v-model="donation.code")
              .p-3.border-bottom
                .row.mx-0.mb-2
                  .col
                    input.my-auto(
                        id="invoice_company"
                      type="radio",
                      name="invoice",
                      :value="invoiceTypeEnum.company",
                      v-model="invoiceType"
                    )
                    label(for="invoice_company") 開立公司發票

                .py-1.collapse(:class="{ show: invoiceType == invoiceTypeEnum.company }")
                  .row.m-0.px-3.py-1
                    .col-md-3.col-12.my-auto 統一編號
                    span.error(v-if="$v.$dirty && $v.company.unified_business_no.$invalid && !$v.company.unified_business_no.required") Code is required
                    span.error(v-else-if="$v.$dirty && $v.company.unified_business_no.$invalid && !$v.company.unified_business_no.isInvoiceＣarrier") Invalid code
                    input.my-auto(type="text" v-model="company.unified_business_no")
                  .row.m-0.px-3.py-1
                    .col-md-3.col-12.my-auto 公司名稱
                    input.my-auto(type="text" v-model="company.company_title")
                  .row.m-0.px-3.py-1
                    .col-md-3.col-12.my-auto 地址
                    input.col.my-auto(type="text" v-model="company.company_address")
            .row.p-3
              input.my-auto(type="checkbox" id="saveInfo" v-model="saveInfo")
              label.col.pr-0.my-auto(for="saveInfo") 我同意紀錄以上資訊作為常用設定
        #buttonSet.row.py-3.justify-content-end
          .col-md-3.col-6.px-3
            button.btn.btn-cancel.w-100.p-3(@click="goBack(true)") 返回
          .col-md-3.col-6.px-3
            button.btn.btn-edit.w-100.p-3(@click="checkFieldsAreReadyAndPay()" :disabled="paying") 
                div(v-if="!paying") 確認付款
                div(v-else) 付款中...
    #pro-description.cover.fixed-top.justify-content-center.align-items-center(
      :class="[displaySubscriptionInfomation ? 'd-flex' : 'd-none']",
      ref="description"
    )
      .col-md-8.col-10.p-4
        .title.row.m-0.border-bottom 
          .col-12.text-center.my-auto 銀卡會員
          .button-close.btn.p-0(@click="displaySubscriptionInfomation = false")
            img(src="@/assets/icon-close-circle.png")
        .body.col.mt-4.p-2.overflow-auto.border
          .col.p-0 
    Popable(v-if="paymentError.failed")
        .col-10.col-md-6.col-lg-4.bg-white.border-rounded
            .px-3
                .d-flex.flex-column.justify-content-center.align-items-center
                    .d-flex.flex-column.justify-content-center.align-items-center.w-100
                        strong.p-3 付款失敗
                        img.position-absolute.align-self-end(src="@/assets/icon-close-circle.png"  @click="()=>{paymentError.failed = false}")
                    .w-100.border-bottom
                    img.mt-2(src="@/assets/fail.png")
                    .text-danger.mt-2 {{paymentError.reason}}
                    .btn.btn-edit.mt-2 
                        .px-5 返回首頁
                    .btn.btn-edit.bg-success.mt-2.mb-5(@click="()=>{paymentError.failed = false}")
                        .px-5 換一張卡
                        


</template>

<script>
import CouponUtil from '../../utils/payment/Coupon'
import Popable from '../utilities/Popable'
import PaymentManager from '../../utils/payment/TapPay'
import PaymentData from '../../models/PaymentData'
import InvoiceInfo from '../../models/InvoiceInfo'
import InvoiceType from '../../models/InvoiceType'
import CarrierTypeEnum from '../../models/CarrierTypeEnum'
import SubscriptionStepNavigator from "./SubscriptionStepNavigator";
import {isCompanyId,isCertificated,isInvoiceＣarrier} from '../../utils/Validator'
import {required} from 'vuelidate/lib/validators'
import {fetchLoveCodes} from '../../api/payment'
import {getCouponDescription} from '../../api/voucher'
// import LoveCode from "../../utils/LoveCode"

const DonationType={
    default:0,
    others:1
}
let paymentManager = undefined
export default {
    components:{Popable,SubscriptionStepNavigator},
    props:{
        env:{
            default:"test",
            type:String
        },
        purchaseItemKey:{
            default:"",
            type:String
        }
    },
    data() {
        return {
            paymentError:{
                failed:false,
                reason:"付款失敗原因"
            },
            coupon:{
                code:"",
                status:"unused",
                description:"無使用折價券"
            },
            purchasedItem:{
                name:undefined,
                price:199,
                discountedPrice:undefined
            },
            donation:{
                organizations:[
                    {name:"第一社會福利基金會",code:'51811'},
                    {name:"財團法人桃園縣私立菩提之家",code:'3712333'},
                ],
                picked:DonationType.default,
                code:'51811'
            },
            carrier:{
                type:CarrierTypeEnum.member,
                mobileCode:"",
                code:""
            },
            company:{
                company_title:"",
                unified_business_no:"",
                company_address:""
            },
            loveCodeSearchTerm:"",
            loveCodes:[],
            saveInfo:false,
            paying:false,
            notTaiwanese:false,
            invoiceType : InvoiceType.person,
            displaySubscriptionInfomation: false,
            displayPaymentError: false,
            isReadyToSubmit:false
        }
    },
    mounted() {
        this.goBackIfNotLogin()
        this.getPlanInfo()
        paymentManager =  new PaymentManager({
            onCardUpdate:this.onCardUpdate
        })
        paymentManager.setupPaymentEnv(this.env != "production")
        fetchLoveCodes().then(res=>{
            if(res.data && res.data.data){
                this.loveCodes = res.data.data
            }
            console.log(res.data)
        })
    },
    methods: {
        loveCodeSelected(item){
            this.loveCodeSearchTerm = item.code + "/" + item.name
            this.donation.code = item.code
        },
        getPlanInfo(){
            let token = this.$store.getters.userToken
            this.$api.getSubscriptionPlan(token,this.purchaseItemKey).then(res=>{
                console.log(res)
                if(res.data && !res.data.error){
                    this.purchasedItem.price = res.data.cost
                }else{
                    this.$alert("Something went wrong").finally(()=>{
                        this.goBack()
                    })
                }
            })
        },
        clearCarrierCode(){
            this.carrier.code = ""
            this.carrier.mobileCode = ""
        },
        cancelCoupon(){
             this.coupon.status = "unused"
             this.coupon.code = ""
             this.coupon.description = "無使用折價券"
            this.purchasedItem.discountedPrice = undefined
        },
        handleDiscount(data){
            if(data.type == CouponUtil.COUPON_TYPE_ENUM.discount && data.currency != "NTD"){
                this.cancelCoupon()
            }
            this.coupon.status = "used"
            this.coupon.description = CouponUtil.discountDescription(data.type, this.purchasedItem.price,data.discount)
            this.purchasedItem.discountedPrice = CouponUtil.discountResult(data.type, this.purchasedItem.price,data.discount)
        },
        useCoupon(){
            this.coupon.status = "checking"
            getCouponDescription(this.coupon.code).then(res=>{
                console.log(res.data)
                if(!res.data ){
                     this.cancelCoupon()
                }
                this.handleDiscount(res.data)
            }).catch(()=>{
                //  this.cancelCoupon()
                 this.coupon.description = "無效兌換碼"
                this.coupon.status = "unused"
                this.purchasedItem.discountedPrice = undefined
            })
            // setTimeout(() => {
            //     this.coupon.status = "used"
            //     this.purchasedItem.discountedPrice = 119
            // }, 2000);
        },
        goBackIfNotLogin(){
            if(!this.$store.getters.userToken){
            this.$alert("login first").finally(()=>{
                this.goBack()
            })
        }
        },
        onPaymentResponse(){

        },
        checkFieldsAreReadyAndPay(){
            this.goBackIfNotLogin()
            if(!this.validateFormFields() || !this.isReadyToSubmit){
                this.$alert("資訊未齊全")
                return 
            }
            this.pay()
        },
        onCardUpdate(canGetPrime){
            this.isReadyToSubmit = canGetPrime
        },
        setPayStatus(status){
            this.payStatus = status
        },
        validateFormFields(){
            this.$v.$touch()
            return !this.$v.$invalid
        },
        pay(){
            let token = this.$store.getters.userToken
            this.paying = true
            // try get prime
            paymentManager.getPrime((success,data)=>{
                this.paying = false
                if(!success){
                    this.paymentError.failed = true
                    this.paymentError.reason = data
                    //send fail msg for log
                    let errorData = {
                        msg:data.msg,
                        status:data.status,
                        plan_id:this.purchaseItemKey,
                        coupon_token:this.coupon.code
                    }
                    this.$api.sendTapPayPrimeError(token,errorData).then(res=>{
                        console.log(res)
                    }).catch(e=>{
                        console.warn(e)
                    })
                    // this.$alert(data)
                    return
                }else{
                    let paymentData = new PaymentData()
                    paymentData.language = this.$store.getters.currentLang.id
                    // paymentData.prime = data.prime
                    paymentData.card = data
                    paymentData.amount = this.purchasedItem.discountedPrice || this.purchasedItem.price
                    paymentData.currency = "NTD"
                    paymentData.coupon_token = this.coupon.code
                    paymentData.plan_id = this.purchaseItemKey
                    let invoiceInfo = new InvoiceInfo()
                    invoiceInfo.type = this.invoiceType
                    if(this.invoiceType == InvoiceType.person){
                        invoiceInfo.carrier_type = this.carrier.type
                        if(this.carrier.type == CarrierTypeEnum.person){
                            invoiceInfo.carrier_no = this.carrier.mobileCode.toUpperCase()
                        }else{
                            invoiceInfo.carrier_no = this.carrier.code.toUpperCase()
                        }
                    }else if(this.invoiceType == InvoiceType.donation){
                        invoiceInfo.donation_code = this.donation.code
                    }else{
                        invoiceInfo.company_title = this.company.company_title
                        invoiceInfo.company_address = this.company.company_address
                        invoiceInfo.unified_business_no = this.company.unified_business_no
                    }
                    invoiceInfo.save = this.saveInfo
                    paymentData.invoice_info = invoiceInfo
                    console.dir(paymentData.toJSON())
                    this.$api.sendTapPayPrime(token,paymentData.toJSON()).then(res=>{
                        console.log(res)
                        if(res.data && !res.data.error){
                            if(res.data.url){
                                window.location.replace(res.data.url);
                            }else{
                                this.$router.replace(`/${this.env}/subscription/success`)
                            }
                        }else{
                            this.paymentError.failed = true
                            this.paymentError.reason = res.data.reason
                            console.warn(res.data.reason)
                        }
                    }).catch(e=>{
                        this.paymentError.failed = true
                        this.paymentError.reason = e
                    })
                }
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        displaySubscriptionInfo(level) {
            console.log(level)
            this.displaySubscriptionInfomation = true
        }
    },
    watch: {
        'carrier.type'(){
            this.clearCarrierCode()
        },
        expire: {
            handler(val) {
                console.log(val)
            },
            deep: true
        },
        "credit.security"(newValue, oldValue) {
            if (isNaN(newValue / 1)) {
                this.credit.security = oldValue
            } else {
                this.credit.security = newValue
            }
        }
    },
    computed:{
        invoiceTypeEnum:()=>InvoiceType,
        carrierTypeEnum:()=>CarrierTypeEnum,
        donationTypeEnum:()=>DonationType,
        filteredLoveCodes(){
            let keyWord = this.loveCodeSearchTerm
            return this.loveCodes.filter(item=>{
                let res = false
                if(item.LoveCode.toString().includes(keyWord)
                    || item.SocialWelfareName.toString().includes(keyWord)){
                        res = true 
                }
                return res
            }).map(item=>{
                return {
                    name:item.SocialWelfareName,
                    code:item.LoveCode
                }
                
            })
        }
    },
    validations(){
        let schema = {
            carrier:{
                mobileCode:{},
                code:{}
            },
            company:{
                unified_business_no:{}
            }
        }
        if(this.invoiceType == this.invoiceTypeEnum.person){
            if(this.carrier.type == this.carrierTypeEnum.person){
                schema.carrier.mobileCode={
                        required,
                        isInvoiceＣarrier
                }
            }else if(this.carrier.type == this.carrierTypeEnum.citizen_digital_certificate){
                schema.carrier.code={
                        required,
                        isCertificated
                    }
            }
        }else if(this.invoiceType == this.invoiceTypeEnum.company){
                schema.company.unified_business_no={
                        required,
                        isCompanyId
                }
        }
        return schema
    }
}
</script>

<style lang="sass" scoped>
.dropdown-menu
    max-height: 300px
    overflow-y: scroll
    .dropdown-item
        &:hover
            cursor: pointer
.border-rounded
    border-radius: 10px
.golden
    background: #FFD028 !important
    .option > div
        background: #FFF4CB
.product-icon
    margin: auto
    width: 30px !important
    height: 30px !important

#privacy
    > div
        height: 40vh
.coupon_field
    display: flex
    input
        max-width: 300px
        flex-grow: 1
    .btn
        min-width: 120px
.payment
    .block
        background: #f7f7f7
        > div
            margin: 10px 0
            .input
                background: #FFFFFF
                border: 1px solid #000000
                box-sizing: border-box
                border-radius: 5px
                padding: 10px
            &:nth-child(1)
                margin-top: 0
            &:last-child
                margin-bottom: 0
    .title
        font-weight: bold
        font-size: 1.5rem
        margin: 10px auto
        .subtext
            font-size: 1.2rem
            font-weight: normal
    .invoice
        > div
            margin: 0

.credit-icon
    width: 45px
    height: auto
    background: lightgray

#result
    .title
        font-weight: bold
        font-size: 2.5rem
        line-height: 47px
        text-align: center
        margin-bottom: 10px
    .button
        color: #FF6B00
        background: #FFDBC1
        font-weight: bold
        font-size: 1.8rem
        line-height: 35px
        border: 2px solid #FF6B00
        box-sizing: border-box
        border-radius: 5px
    .background-FF6B00
        color: white
        background: #FF6B00

.cover
    width: 100vw
    height: 100%
    background: rgba(35, 35, 44, 0.9)
    > div
        background: #FFFFFF
        border-radius: 15px
    .title
        position: relative
        height: 40px
        font-size: 1.6rem
        font-weight: bold
    .button-close
        position: absolute
        top: 0
        right: 0
        &:hover
            background: lightgray

#pro-description
    .body
        max-height: 50vh

#sub-error
    .button
        color: white
        background: #FF6B00
        font-weight: bold
        font-size: 1.8rem
        line-height: 35px
        box-sizing: border-box
        border-radius: 5px
    .background-00B900
        color: white
        background: #00B900
    .body > div
        color: #EA4335
        margin: 10px auto
.basic
    border-color: rgb(232, 232, 232) !important
.tpfield
    background-color: #fff
    height: 40px
    width: 300px
    border: 1px solid gray
    margin: 5px 0
    padding: 5px
.deleted-text
    text-decoration: line-through
</style>