class Language{
    constructor(key,name,id){
        this.key = key
        this.name = name
        this.id = id
    }
}
const LanguageEnum = Object.freeze({
    "zh":new Language("zh","中文",1),
    "en":new Language("en","EN",2),
})
export default LanguageEnum