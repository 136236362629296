<template lang="pug">
  .container-fluid.projects.mt-3    
        .p20
            #user-projects.title.font-weight-bold Favorites
            ProjectList(:projects="projects")
        Paginator(:currentPage="currentPage" :pages="pages" @goToPage="goToPage")
</template>

<script>
import ProjectList from '../ProjectList'
import Paginator from '../Paginator'
export default {
    components:{ProjectList,Paginator},
data() {
    return {
        projects:[],
        currentPage:0,
        // per:2,
        per:24,
        pages:0,
    }
},
beforeMount(){
  
    this.goToPage(1)
},
methods: {
    goToPage(n){
        this.projects = undefined
        this.$api.getUserLikedProjects(this.$store.state.user.token,n,this.per).then(res=>{
        this.projects = res.data.items
        // console.log(res.data)
        this.pages = Math.ceil(res.data.page.total /  res.data.page.per)
        this.currentPage = res.data.page.page
    })
    }
},
}
</script>

<style lang="sass" scoped>
.p20
    padding-left: 10%
    padding-right: 10%
</style>