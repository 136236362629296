<template lang="pug">
    .container
        .row
            .col.text-center {{$t('utility.coupon')}}
            .col.text-center {{$t('label.redeemDate')}}
        .coupons.mt-3.border
            .row.border-bottom.justify-content-centers.align-items-center(v-for="coupon in coupons")
                .col.text-center {{getDescription(coupon.description)}}
                .col.text-center {{coupon.redeemed_date}}
        
</template>

<script>
import {getRedeemedVoucher} from '../../api/voucher'
export default {
data() {
    return {
        coupons:[
            
        ]
    }
},
methods: {
    getDescription(data){
        var userLang = navigator.language || navigator.userLanguage; 
            if(userLang && userLang.includes('zh')){
                    return data.tw
            }
            return data.en
    }
},
beforeMount() {
    getRedeemedVoucher().then(res=>{
        this.coupons = res.data
        this.coupons.reverse()
    })
},
}
</script>

<style lang="sass" scoped>
.coupons
    height: 325px
    overflow: scroll
    .row
        height: 50px

</style>