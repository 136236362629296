import PaymentManager from './PaymentManager'
export default class TapPay extends PaymentManager {
   
    get TPDirect(){
        return window.TPDirect
    }

  

    getPrime(onPaymentResponse=()=>{}) {
        // 取得 TapPay Fields 的 status
        const tappayStatus = this.TPDirect.card.getTappayFieldsStatus()

        // 確認是否可以 getPrime
        if (tappayStatus.canGetPrime === false) {
            // alert('can not get prime')
            onPaymentResponse(false,{status:90001,msg:"Fields Error"})
            return
        }

        // Get prime
        this.TPDirect.card.getPrime((result) => {
            if (result.status !== 0) {
                console.log('get prime error ' + result.msg)
                let {status,msg} = result
                onPaymentResponse(false,{status,msg})
                return
            }
            console.log('get prime 成功，prime: ' + result.card.prime)
            onPaymentResponse(true,result.card)
         })
    }
    setupPaymentEnv(isSandbox) {
        // this.TPDirect.setupSDK(11327, 'app_whdEWBH8e8Lzy4N6BysVRRMILYORF6UxXbiOFsICkz0J9j1C0JUlCHv1tVJC', isSandbox?'sandbox':'production')
        this.TPDirect.setupSDK(18599, 'app_pfBhy4VJ7gpwyjokLQKUEVWp1lVyEdFUj2TZDXepDaFPoYu0BnL7Uy9bOh3X', isSandbox?'sandbox':'production')
        // Display ccv field
        let fields = {
            number: {
                // css selector
                element: '#card-number',
                placeholder: '**** **** **** ****'
            },
            expirationDate: {
                // DOM object
                element: document.getElementById('card-expiration-date'),
                placeholder: 'MM / YY'
            },
            ccv: {
                element: '#card-ccv',
                placeholder: 'ccv'
            }
        }
        this.TPDirect.card.setup({
            fields: fields,
            styles: {
                // Style all elements
                'input': {
                    'color': 'gray'
                },
                // Styling ccv field
                'input.ccv': {
                    // 'font-size': '16px'
                },
                // Styling expiration-date field
                'input.expiration-date': {
                    // 'font-size': '16px'
                },
                // Styling card-number field
                'input.card-number': {
                    // 'font-size': '16px'
                },
                // style focus state
                ':focus': {
                    // 'color': 'black'
                },
                // style valid state
                '.valid': {
                    'color': 'green'
                },
                // style invalid state
                '.invalid': {
                    'color': 'red'
                },
                // Media queries
                // Note that these apply to the iframe, not the root window.
                '@media screen and (max-width: 400px)': {
                    'input': {
                        'color': 'orange'
                    }
                }
            }
        })
        this.registerOnCardUpdate()
    }
    registerOnCardUpdate(){
        this.TPDirect.card.onUpdate(function (update) {
            this.delegate.onCardUpdate(update.canGetPrime)
            // update.canGetPrime === true
            // --> you can call TPDirect.card.getPrime()
            if (update.canGetPrime) {
                // Enable submit Button to get prime.
                // submitButton.removeAttribute('disabled')
            } else {
                // Disable submit Button to get prime.
                // submitButton.setAttribute('disabled', true)
            }
        
            // cardTypes = ['mastercard', 'visa', 'jcb', 'amex', 'unionpay','unknown']
            if (update.cardType === 'visa') {
                // Handle card type visa.
            }
        
            // number 欄位是錯誤的
            if (update.status.number === 2) {
                // setNumberFormGroupToError()
            } else if (update.status.number === 0) {
                // setNumberFormGroupToSuccess()
            } else {
                // setNumberFormGroupToNormal()
            }
        
            if (update.status.expiry === 2) {
                // setNumberFormGroupToError()
            } else if (update.status.expiry === 0) {
                // setNumberFormGroupToSuccess()
            } else {
                // setNumberFormGroupToNormal()
            }
        
            if (update.status.ccv === 2) {
                // setNumberFormGroupToError()
            } else if (update.status.ccv === 0) {
                // setNumberFormGroupToSuccess()
            } else {
                // setNumberFormGroupToNormal()
            }
        }.bind(this))
    }

}