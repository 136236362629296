<template lang="pug">
    .container
        .row
            .col-12.col-md-3.p-2
                .border
                    //- 個人簡介
                    .row.p-3.profile
                        .col-5
                            .square
                                img.user-photo.inner.rounded-circle(:src="user.photolink")
                        .col-7
                            .row 
                                .col.pl-0.membership
                                    img.mr-1(v-if="user.membership=='Gold'" src="@/assets/golden.png")
                                    img.mr-1(v-if="user.membership=='Silver'" src="@/assets/silver.png")
                                    | {{user.nickname}}
                            .row.sub.mt-2
                                .col.p-0.border-right(v-if="user.nation") {{user.nation}}
                                .col.p-0.pl-1(v-if="user.gender") {{getGenderString(user.gender)}}
                                .col.p-0.pl-1.border-left(v-if="user.age") {{user.age}}
                            .row.sub.mt-2
                                .col.p-0 {{user.email}}
                    .row.p-3
                        .col.border-top
                            .square
                                .inner.description {{user.description}}
            .col-12.col-md-9
                //- 作品
                ProjectList(:projects="projects")
                Paginator.row(:currentPage="currentPage" :pages="pages" @goToPage="goToPage")
</template>

<script>
import ProjectList from './ProjectList'
import Paginator from './Paginator'
import genderTool from './utilities/gender'
export default {
    props:[
        "id"
    ],
    components:{
        ProjectList,Paginator
    },
    data() {
        return {
            per:24,
            currentPage:1,
            pages:0,
            user:{
                nickname:"",
                email:"",
                age:undefined,
                gender:undefined,
                nation:undefined,
                description:"",
                photolink:"",
                membership:""
            },
            projects:[]
        }
    },
    beforeMount() {
        this.goToPage(1)
        this.$api.getUserProfile(this.id).then(res=>{
            let data =res.data
            // console.log(data)
            let extra = data.extra_info || {}
            for(let key in this.user){
                if(data[key]){
                    this.user[key] = data[key]
                }else if(extra[key]){
                    this.user[key] = extra[key]
                }
            }
        })
        
    },
    methods: {
      getGenderString(code){
        return genderTool.codeToGenderString(code)
      },
        goToPage(n){
        let token =  this.$store.state.user? this.$store.state.user.token:undefined
            this.projects = undefined
        this.$api.getUserProject(this.id,n,this.per,token).then(res=>{
            
            let data = res.data.items
            this.projects = data.map(p=>{
                p.author={
                    uuid:this.id,
                    name:this.user.nickname,
                    ...this.user
                }
                return p
            })
            // console.log(this.projects)
        })
        //  this.$api.searchAllProjects({
        //     page:n,
        //     per:this.per,
        //     range:["user"],
        //     sortBy:'c',
        //     searchFrom:"None"
        // },token
        // ).then(res=>{
        //     this.projects = res.data.items
        //     this.pages = Math.ceil(res.data.page.total /  res.data.page.per)
        //     this.currentPage = res.data.page.page
        // })
        }
    },

}
</script>

<style lang="sass" scoped>
.membership
    img
        width: 20px
        height: 20px
.sub
    font-size: 0.8rem
.description
    overflow: scroll
    word-wrap: break-word
.profile  .row
    width: 100%
    overflow: scroll
.user-photo:after
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: url('../assets/member-photo.png') #fff
    background-size: contain
</style>