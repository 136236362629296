<template lang="pug">
    .home
        .container-fluid.projects.pt-5 
            .p20#search-projects
                .title.font-weight-bold {{type=="official"?$t('label.officialProjects'):""}}
                ProjectList(v-if="!searching" :projects="projects")
                .row.justify-content-center(v-else)
                    | {{$t('label.loading')}}
            Paginator(:currentPage="currentPage" :pages="pages" @goToPage="goToPage")

</template>

<script>
import Paginator from './Paginator'
import ProjectList from './ProjectList'
export default {
    props:["type"],
    components:{    
        ProjectList,Paginator
},
data() {
    return {
        searching:false,
        projects:[],
        currentPage:1,
        per:12,
        // per:2,
        pages:0,
        options:{}
    }
},
mounted() {
     this.search()
},
beforeMount(){
    this.$bus.$on('filter', () => {
            this.search()
        });
    // this.search()
},
beforeDestroy(){
     this.$bus.$off('filter');
},
methods:{
    search(){
        this.currentPage = 1
        this.searching = true
        let filter = this.$store.state.filterOptions
        let sortBy =  this.$store.state.sortBy || "c"
        let searchTerm =  this.$store.state.searchTerm

        let options={
            page:this.currentPage,
            per:this.per,
            sortBy
        }
        if(filter.range){
            options.range = Object.keys(filter.range.options).filter(key=>{return filter.range.options[key].selected})
            if(!options.range.length){
                options.range = ["official","user"]
            }
        }
    
        
        if(filter.machine_type){
            options.machine_type = Object.keys(filter.machine_type.options).filter(key=>{
                return filter.machine_type.options[key].selected
                })
            if(!options.machine_type.length){
                options.machine_type = undefined
            }
        }
        //會員等級
        if(filter.membership){
            options.membership =  Object.keys(filter.membership.options).filter(key=>{return filter.membership.options[key].selected})
            if(!options.membership.length){
                options.membership = undefined
            }
        } 

        //搜尋文字
        if(searchTerm){
            searchTerm = searchTerm.trim()
            if(searchTerm.length){
                options.searchTerm = searchTerm
            }
        }
        
        if(filter.searchFrom){
            let searchFrom = Object.keys(filter.searchFrom.options).filter(key=>{return filter.searchFrom.options[key].selected})
            if(searchFrom.length == 1){
                options.searchFrom = searchFrom[0]
            }else if(searchFrom.length == 2){
                options.searchFrom = "All"
            }else{
                options.searchFrom = "None"
            }
            if(!options.searchTerm){
                options.searchFrom = "None"
            }
        }
        //Materials
        let materials = this.$store.state.materialList
        if(materials){
            let selectedMaterials = materials.filter(m=>m.selected).map(m=>m.listing.english)
            if(selectedMaterials.length){
            options.materials =  selectedMaterials
            }
            // console.log(materials.filter(m=>m.selected).map(m=>m.name))
            
        }

        //如果是首頁點擊官方專案
        if(this.type=="official"){
            delete options.materials
            delete options.membership
            delete options.machine_type
            options.range = ["official"]
        }

        //紀錄選項
        this.options = options
        console.log(JSON.parse(JSON.stringify(options)) )

        let token =  this.$store.state.user? this.$store.state.user.token:undefined
        this.$api.searchAllProjects(options,token
        ).then(res=>{
            this.projects = res.data.items
            this.currentPage = res.data.page.page
            this.per = res.data.page.per
            this.pages = Math.ceil(res.data.page.total /  res.data.page.per)
            this.searching = false
            // console.log(this.projects)
        })
    },
    goToPage(n){
        // n = parseInt(n)
        // if(this.pages!=0 && ( n > this.pages ||  n <= 0) ){
        //     return
        // }
        // this.currentPage = n
        this.options.page = n

        let token =  this.$store.state.user? this.$store.state.user.token:undefined
        this.searching = true
         this.$api.searchAllProjects(this.options,token
        ).then(res=>{
            this.projects = res.data.items
            this.currentPage = res.data.page.page
            this.wannaPage = this.currentPage
            this.per = res.data.page.per
            this.pages = Math.ceil(res.data.page.total /  res.data.page.per)
            this.searching = false
        }).catch(()=>{
            this.searching = false
        })
    },
    // nextPage(n){
    //      this.toPage(this.currentPage + n)
        
    // }
}
}
</script>

<style lang="sass">

.p20
    padding-left: 10%
    padding-right: 10%
.pagination
    input
        width: 40px
</style>