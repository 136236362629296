<template lang="pug">
   .row.mt-5.pagination
        .col-md
        .col-md.col-12.d-flex.justify-content-center
            .inner
                a.btn.btn-edit(v-if="myCurrentPage>1" href="#user-projects" @click="nextPage(-1)") {{$t('button.previous')}}
                a.btn.btn-edit(v-if="myCurrentPage<pages" :class="{'ml-2':myCurrentPage>1}" href="#user-projects" @click="nextPage(1)") {{$t('button.next')}}
        .col-md.col-12.d-flex.justify-content-center
            .inner.d-flex.justify-content-center.align-items-center
                span {{$t('label.page')}}
                input(v-model="wannaPage").m-1
                | | {{pages}}
                .btn.btn-edit.ml-2(@click="toPage(wannaPage)") {{$t('button.paginatorGo')}} 
</template>

<script>
export default {
    props:["pages","currentPage"],
data() {
    return {
        wannaPage:0,
        myCurrentPage:0
    }
},
mounted(){
    this.wannaPage = this.currentPage
    this.myCurrentPage = this.currentPage
},
watch:{
    currentPage(val){

         this.wannaPage = val
        this.myCurrentPage = val
    }
},
methods:{
    nextPage(n){
         this.toPage(this.myCurrentPage + n)
        
    }, toPage(n){
        n = parseInt(n)
        if(this.pages!=0 && ( n > this.pages ||  n <= 0) ){
            return
        }
        this.wannaPage = n
        this.myCurrentPage = n
        this.$emit("goToPage", n)
    }
}
}
</script>

<style lang="sass" scoped>

</style>