<template>
<div id="cover" >
    <div class="container col-8 col-md-6 col-lg-4">
      <div class="row logo-img mt-3 justify-content-center">
        <img src="@/assets/logo.png" alt="logo" />
      </div>
      <div class="introduction justify-content-center row mb-2">{{$t('slogan')}}</div>
      <!-- 登入 -->
      <div id="login" class="content" v-if="mode=='login'">
        <div class="header border-bottom pb-2">
          <div class="row m-0 justify-content-center align-items-center">
            <div>{{$t('title.login')}}</div>
            <div class="button-close d-flex justify-content-center align-items-center" @click="closeLoginView()">
              <img src="@/assets/icon-close-circle.png" alt="close icon" />
            </div>
          </div>
        </div>
        <div class="mt-3">
        <div class="error" v-if="loginForm.invalid && !$v.loginForm.email.required">* {{$t('error.required')}}</div>
        <div class="error" v-else-if="loginForm.invalid && !$v.loginForm.email.email">* {{$t('error.email_format')}}</div>
          <div class="error" v-else-if="loginForm.loginFail">{{$t('error.account_password')}}</div>
        <input v-model="loginForm.email" class="col-12 " type="email" :placeholder="$t('title.email')" required>
</div>
        <div class="mt-3">
        <div class="error" v-if="loginForm.invalid && !$v.loginForm.password.required">* {{$t('error.required')}}</div>
        <div class="error" v-if="loginForm.invalid && !$v.loginForm.password.minLength">* {{$t('error.account_password')}}</div>
        <input v-model="loginForm.password" class="col-12 " type="password" :placeholder="$t('title.password')" required>
        </div>

        <div class="rec">
          <!-- <div id="recaptcha" class="g-recaptcha mt-3 col-12 d-flex justify-content-center align-items-center" data-sitekey="6LekAxMaAAAAAEjOSbDEmqhFV5zSgJvZ_ztD-HvM" ></div> -->
          <div id="recaptcha" class="g-recaptcha mt-3 col-12 d-flex justify-content-center align-items-center"  ></div>
        </div>
        <button class="w-100 btn-edit mt-3 btn " @click="userLogin()" :disabled="!recaptchaToken">{{$t('button.login')}}</button>
        <div class=" row mt-3 justify-content-center align-items-center">
          <div class="btn" @click="switchView('register')">{{$t('button.signup')}}</div>
          <div class="btn ml-3 text-muted" @click="switchView('forget')">{{$t('button.forgot_password')}}</div>
        </div>
      </div>
      <!-- 註冊 -->
      <div id="registration" class="content p-4 mx-auto" v-else-if="mode == 'register'">
        <div class="header">
          <div class="row m-0 justify-content-center align-items-center">
            <!-- <div class="square40"></div> -->
            <div>{{$t('title.signup')}}</div>
            <div class=" button-close  " @click="closeLoginView()">
              <img src="@/assets/icon-close-circle.png" alt="close icon" />
            </div>
          </div>
          <div class="baseline"></div>
        </div>

        <div class="mt-3">
            <div class="error" v-if="emailForm.invalid && !$v.emailForm.email.required">* {{$t('error.required')}}</div>
            <div class="error" v-else-if="emailForm.invalid && !$v.emailForm.email.email">* {{$t('error.email_format')}}</div>
            <div class="error" v-else-if="emailForm.used">{{$t('error.email_taken')}}</div>
       
        <input v-model="emailForm.email" class="col-12 " type="email" :placeholder="$t('title.email')" required>
         </div>
        <div class="cubiio-button-large mt-3 btn" @click="sendRegisterEmail()">{{$t('button.send')}}</div>
        <div class="mt-3 row justify-content-center align-items-center">
          <div class="btn" @click="switchView('login')">{{$t('button.login')}}</div>
        </div>
      </div>
      <!-- email已寄出 -->
      <div id="emailSent" class="content p-4 mx-auto" v-else-if="mode == 'emailSent'">
        <div class="header">
          <div class="px-3 row m-0 justify-content-center align-items-center text-center">
            <div>{{$t('title.signup')}}</div>
            <div class="square40 button-close d-flex justify-content-center align-items-center" @click="closeLoginView()">
              <img src="@/assets/icon-close-circle.png" alt="close icon" />
            </div>
          </div>
          <div class="baseline"></div>
        </div>
        <div class="text-center mt-3 ">{{$t('article.register_email_sent')}}</div>
        <div class="text-center">{{$t('article.register_email_sent2')}}</div>
        <button class="cubiio-button-large mt-3 btn w-100" @click="sendRegisterEmail()"  :aria-disabled="resendEmail.disabled" :disabled="resendEmail.disabled">{{$t('button.send_again')}}({{resendEmail.countdown}})</button>
      </div>
      <!-- 忘記密碼 -->
      <div id="forget" class="content p-4 mx-auto" v-else-if="mode == 'forget'">
        <div class="header">
          <div class="px-3 row m-0 justify-content-center align-items-center text-center">
            <div>{{$t('title.forgot_password')}}</div>
            <div class="square40 button-close d-flex justify-content-center align-items-center" @click="closeLoginView()">
              <img src="@/assets/icon-close-circle.png" alt="close icon" />
            </div>
          </div>
          <div class="baseline"></div>
        </div>

        <div class="mt-3">
            <div class="error" v-if="forgetPasswordForm.invalid && !$v.forgetPasswordForm.email.required">* {{$t('error.required')}}</div>
            <div class="error" v-else-if="forgetPasswordForm.invalid && !$v.forgetPasswordForm.email.email">* {{$t('error.email_format')}}</div>
            <div class="error" v-else-if="forgetPasswordForm.notFound">{{$t('error.email_notfound')}}</div>
        <input v-model="forgetPasswordForm.email" class="col-12" type="email" :placeholder="$t('title.email')" required>
        </div>
        <div class="cubiio-button-large mt-3 btn" @click="sendResetMail()">{{$t('button.submit')}}</div>
      </div>
      <!-- 已寄送重設信件 -->
      <div id="sent" class="content p-4 mx-auto" v-else-if="mode == 'forgetEmailSent'">
        <div class="header">
          <div class="px-3 row m-0 justify-content-center  align-items-center text-center">
            <div>{{$t('title.reset_password')}}</div>
            <div class="square40 button-close d-flex justify-content-center align-items-center" @click="closeLoginView()">
              <img src="@/assets/icon-close-circle.png" alt="close icon" />
            </div>
          </div>
          <div class="baseline"></div>
        </div>
        <div class="body row m-0 justify-content-center">
          <div class="text-center mt-3 mb-3">{{$t('article.forgot_password_email_sent')}}</div>
          <div class="text-center">{{$t('article.forgot_password_email_sent2')}}</div>
          <button class="cubiio-button-large mt-3 btn w-100" @click="sendResetMail()"  :aria-disabled="resendEmail.disabled" :disabled="resendEmail.disabled">{{$t('button.send_again')}}({{resendEmail.countdown}})</button>
     
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import email from "./utilities/email";

export default {
    props:{
        onClose:Function,
        modeProp:String
    },
    data(){
      return{
        mode:"login",
        loginForm:{
            email:"",
            password:"",
            invalid:false,
            loginFail:false
        },
        forgetPasswordForm:{
            email:"",
            invalid:false,
            notFound:false
        },
        emailForm:{
            email:"",
            invalid:false,
            used:false
        },
        registerForm:{
            invalid:false,
            nickName:"",
            password:"",
            passwordConfirm:""
        },
        resendEmail:{
            timer:undefined,
            disabled:true,
            countdown:60
        },
        recaptchaToken:undefined,
        recaptchaSettimeout:undefined
      } 
    },
    mounted(){
        this.mode = this.modeProp
        this.switchView(this.mode )
        this.$v.$reset()
    },
    beforeDestroy(){
      clearTimeout(this.recaptchaSettimeout)
    },
    methods: {
        switchView(newMode){
            // newMode="emailSent" 
            this.mode = newMode
            //  console.log(window.grecaptcha)
            if(this.resendEmail.timer){
                clearInterval(this.resendEmail.timer)
                this.resendEmail.timer = undefined
            }
            if(newMode == "emailSent" || newMode == "forgetEmailSent"){
                this.resendEmail.disabled = true
                this.resendEmail.countdown = 60
                this.resendEmail.timer = setInterval(function(){
                    this.resendEmail.countdown-=1
                    if(this.resendEmail.countdown<=0){
                        this.resendEmail.disabled = false
                        clearInterval(this.resendEmail.timer)
                        this.resendEmail.timer = undefined
                    }
                }.bind(this),1000)
            }else if(newMode == "login"){
              this.initRecaptcha()
              // console.log("grecaptcha_sitekey",process.env.VUE_APP_grecaptcha_sitekey)
              // let lang = (navigator.language || navigator.browserLanguage).toLowerCase()
              // console.log("lang",lang)
              // let vm = this
              // this.$nextTick(function() {
              //    window.grecaptcha.render('recaptcha', {
              //      hl:lang,
              //   // 'sitekey' : '6LekAxMaAAAAAEjOSbDEmqhFV5zSgJvZ_ztD-HvM',
              //   // 'sitekey' : '6Lek8BIaAAAAABPoeALkQ6ZMmJkoscyfEUEcuLWX',
              //   'sitekey' : process.env.VUE_APP_grecaptcha_sitekey,
              //   'callback' : vm.setRecaptchaToken,
              //   });
              // });
                 
            }
        },
        initRecaptcha(){
          let lang = (navigator.language || navigator.browserLanguage).toLowerCase()
          this.recaptchaSettimeout = setTimeout(function(){
            if (typeof window.grecaptcha === 'undefined' || typeof window.grecaptcha.render === 'undefined'){
              this.initRecaptcha();
            }else{
              console.log(process.env.VUE_APP_grecaptcha_sitekey)
              window.grecaptcha.render('recaptcha', {
                hl:lang,
                // 'sitekey' : '6LekAxMaAAAAAEjOSbDEmqhFV5zSgJvZ_ztD-HvM',
                // 'sitekey' : '6Lek8BIaAAAAABPoeALkQ6ZMmJkoscyfEUEcuLWX',
                'sitekey' : process.env.VUE_APP_grecaptcha_sitekey,
                'callback' : this.setRecaptchaToken,
                });
            }
          }.bind(this),100)
        },
        setRecaptchaToken(token){
          this.recaptchaToken = token
        },
        closeLoginView(){
            this.onClose()
        },
        userLogin(){
                // console.log(event)
                this.loginForm.loginFail = false
                this.loginForm.invalid = this.$v.loginForm.$invalid
                if(this.loginForm.invalid){return}
                let {email,password} = this.loginForm
                   this.$api.login(email,password,this.recaptchaToken).then(res=>{
                       if(res.error){
                            this.loginForm.loginFail = true
                           return
                       }else{
                           let data = res.data
                           this.$appBridge.login(data.token)
                           this.$store.commit("updateUser",data)
                           
                        this.onClose()
                        this.goHome()
                       }
                      //  console.log(res.data)
                   }).catch(()=>{
                      //  console.log(error)
                            this.loginForm.loginFail = true
                   })
                  
        },goHome(){
            this.$router.push('/').catch(()=>{
                this.$router.go(0)
            })
        },
        sendRegisterEmail(){
            // console.log(this.$v.emailForm.email.email)
            this.emailForm.invalid = this.$v.emailForm.$invalid
            let {email} = this.emailForm
            if(this.emailForm.invalid ){return}
            this.$api.emailExistCheck(email).then(res=>{
                let data = res.data
                // console.log("emailExistCheck",res.data)
                if(data.result){ //Email 已被使用
                    this.emailForm.used = true
                }else{
                    this.$api.sendConfirmEmail(email).then(res=>{
                        if(res.data.error){
                            // console.log(res.data.reason)
                        }else{
                            this.switchView("emailSent")
                        }
                    })
                }
            })
        },
        sendResetMail(){
            
            this.forgetPasswordForm.invalid = this.$v.forgetPasswordForm.$invalid
            if(this.forgetPasswordForm.invalid){
              return
            }
            this.$api.forgetPassword(this.forgetPasswordForm.email).catch(()=>{

            }).finally(()=>{
               this.switchView("forgetEmailSent")
            })
        }
    },
  validations: {
    loginForm: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
    emailForm: {
      email: {
        required,
        email,
      },
    },
    forgetPasswordForm: {
      email: {
        required,
        email,
      },
    },
    registerForm: {
      nickName: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      passwordConfirm: {
        sameAsPassword: sameAs("registerForm.password"),
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.introduction
  color: white
#cover
  width: 100vw
  height: 100vh
  background: rgba(10, 10, 20, 0.7)
  overflow: scroll
  z-index: 10
  position: fixed
  top: 0
  left: 0

  /* Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none

  /* IE and Edge
  scrollbar-width: none

  /* Firefox

.content
  /* min-height: 440px;
  /* margin: 48px auto 200px auto;
  font-size: 1rem
  background: #ffffff
  border-radius: 15px
  padding: 5%
  img
    &:hover
      cursor: pointer

  input
    /* width: 100%;
    /* height: 90px;
    /* margin-top: 20px;
    background: #FFFFFF
    border: 1px solid #DBDBDB
    box-sizing: border-box

    /* box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px
    padding: 10px 10px

.baseline
  width: 100%
  margin-top: 30px
  height: 0
  border: 1px solid #ffffff
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)

.button-close
  position: absolute
  right: 0
  top: 0
  border-radius: 5px

.body
  width: 100%
  padding-top: 24px
  > * 
    width: 100%
    max-width: 400px
  > input, .large-button, .emailSent-text
    height: auto
    min-height: 48px
    margin: 10px 0

.baseline
  width: 100%
  margin-top: 10px
  height: 0
  border: 1px solid #ffffff
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)

.header
  position: relative;
  font-size: 1.2rem;

.footer
  margin-top: 40px;
  &:hover
    background-color: lightgray
    cursor: pointer

  img
    width: 36.67px
    height: 36.67px

.footer
  div
    max-width: 100px
    font-weight: 500
    font-size: 24px
    line-height: 33px
    text-align: center
    margin: auto 10px
    cursor: pointer
    &:hover
      text-decoration: underline

.footer-black
  color: #0a0a14

.footer-lightgray
  color: #b1b0b0

.large-button
  background: #FF6B00
  box-shadow: inset 0px -5px 0px #D95B00
  border-radius: 7.5px
  font-weight: 500
  font-size: 24px
  line-height: 41px
  letter-spacing: 0.15em
  color: white
  &:hover
    cursor: pointer
  &:disabled
    background: #FFB37C
    box-shadow: inset 0px -5px 0px #D95B00
    cursor: default

.emailSent-text
  height: 24px
  font-size: 30px
  line-height: 35px
  text-align: center
</style>