<template lang="pug">
    .container
        .btn.btn-edit.m-3(@click="uploadDoddle") {{$t("button.upload")}}
        .container-fluid.pb-2(v-if="uploadedDoodles && uploadedDoodles.length")
                .row
                    .col-xl-2.col-lg-3.col-md-3.col-sm-4.col-12.p-2(v-for="doodle in uploadedDoodles" )
                        ImageCard(:item="doodle")

        
</template>

<script>
import ImageCard from './ImageCard.vue'
import {getUploadedDoodles} from '../api/doodle'
export default {
    components:{
        ImageCard
    },
    data(){
        return{
            uploadedDoodles:[],
            currentPage:0,
            per:20,
            token:undefined
        }
    },
    
    beforeMount(){
        this.token = this.$store.state.user.token
        if(!this.token){
            this.$router.push("/")
            return
        }
        console.log(this.$store.state.user.user.nickname)
        getUploadedDoodles(this.$store.state.user.user.nickname).then(res=>{
            console.log(res.data)
            this.uploadedDoodles = res.data.map(o=>{return{image_url:o.imagelink, title:o.name}});
        })

    },methods:{
        uploadDoddle(){
            this.$router.push(`/me/uploaddoodle`)
        },

    }
}
</script>

<style lang="sass" scoped>
.projects
</style>