import instance from './instance'

export function getMsgList(status,page,per){
    return instance
    .get(`/push/message?status=${status}&page=${page}&per=${per}`)
  }

  export function getMsgDetail(id){
    return instance
    .get(`/push/message/${id}`)
  }

  export function createAppeal(payload){
    return instance
    .post(`/appeal`,payload)
  }

  export function getAppealList(report_id){
    return instance
    .get(`/appeal/${report_id}`)
  }