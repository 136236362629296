<template lang="pug">
    StepNavigator(:steps="steps" :currentStepKey="currentStepKey")
</template>

<script>
import StepNavigator from '../StepNavigator'
export default {
    props:{
        currentStepKey:{
            type:String,
            default:"1"
        }
    },
    components:{StepNavigator},
    data(){
        return{
            steps:[
                {
                    title:"1.選擇訂閱項目",
                    key:"1",
                },
                {
                    title:"2.使用者條款",
                    key:"2",
                },
                {
                    title:"3.付款",
                    key:"3",
                },
                {
                    title:"4.訂閱完成",
                    key:"4",
                },
            ]
        }
    }

}
</script>

<style>

</style>