<template lang="pug">
    .container.mt-3
        .row.justify-content-center.align-items-center
            .col-10.col-md-8.d-flex.flex-column.justify-content-center.align-items-center.p-3.rounded.main(:class="[currentState.borderClass]")
                .font-weight-bold.title.mt-3.text-center(:class="[currentState.textClass]") {{currentState.title}}
                .font-weight-bold.mt-3 {{msg}}
                .mt-5.w-75.p-3.rounded.input
                    input.w-100(v-model.lazy="coupon", :disabled="pending || currentState.disableInput" :placeholder="$t('article.enterCode')")
                button.btn.btn-edit.mt-5.mb-3(v-if="currentState.submitButton.length" @click="submit" :disabled="pending || failHandler.countdown > 0") {{currentState.submitButton}} 
                    span(v-if="failHandler.countdown") ({{failHandler.countdown}})
                button.btn.btn-cancel.mb-3(v-if="currentState.cancelButton.length" @click="cancel" :disabled="pending" )  {{currentState.cancelButton}}
</template>

<script>
export default {
    data() {
        return {
            status:{
                beforeCheck:{
                    title:this.$t('title.redeemTitle'),
                    submitButton:this.$t('button.check'),
                    cancelButton:"",
                    textClass:"",
                    borderClass:"",
                },
                checking:{
                    title:this.$t('title.checking'),
                    submitButton:"",
                    cancelButton:"",
                    textClass:"",
                    borderClass:"",
                },
                checkFailed:{
                    title:this.$t('title.couponNotFound'),
                    submitButton:this.$t('button.tryAgain'),
                    cancelButton:"",
                    textClass:"text-danger",
                    borderClass:"",
                },
                beforeSubmit:{
                    title:this.$t('title.confirm'),
                    submitButton:this.$t('button.redeemCoupon'),
                    cancelButton:this.$t('button.no'),
                    textClass:"",
                    borderClass:"",
                    disableInput:true
                },
                redeeming:{
                    title:this.$t('title.redeeming'),
                    submitButton:"",
                    cancelButton:"",
                    textClass:"",
                    borderClass:"",
                },
                redeemSuccess:{
                    title:this.$t('title.success'),
                    submitButton:this.$t('button.redeemAnother'),
                    cancelButton:this.$t('button.gotoRedeemHistory'),
                    textClass:"text-success",
                    borderClass:"border-success",
                },
                redeemFailed:{
                    title:this.$t('title.failed'),
                    submitButton:this.$t('button.redeemAnother'),
                    cancelButton:"",
                    textClass:"text-danger",
                    borderClass:"border-danger",
                }
            },
            currentState:{
                    title:this.$t('title.redeemTitle'),
                    submitButton:this.$t('button.check'),
                    cancelButton:"",
                    textClass:"",
                    borderClass:"",
                },
            msg:"",
            submitBtnTitle:"Redeem",
            coupon:"",
            pending:false,
            failHandler:{
                failTimes:0,
                countdown:0
            }
        }
    },mounted() {
        this.currentState = this.status.beforeCheck
    },
    methods: {
        init(){
                this.coupon = ""
                this.msg = ""
                this.currentState = this.status.beforeCheck
        },
        submit(){
            switch(this.currentState){
                case this.status.beforeCheck:
                case this.status.checkFailed:
                    this.checkCode()
                    break
                case this.status.beforeSubmit:
                    this.submitCode()
                    break
                case this.status.redeemSuccess:
                case this.status.redeemFailed:
                    this.init()
                    break

            }
        },
        cancel(){
             switch(this.currentState){
                case this.status.beforeSubmit:
                    this.init()
                    break
                case this.status.redeemSuccess:
                    this.$router.push('/me/coupon/history')
                    break
            }
        },
        checkCode(){
            this.coupon = this.coupon.trim()
            let token = this.$store.state.user.token
            if(!token || this.coupon.length==0){
                return
            }
            // let coupon = this.coupon
            this.msg = ""
            this.currentState = this.status.checking
            this.$api.getCouponDescription(this.coupon,token).then(res=>{
                let data = res.data
                if(res.status!=200 || !data){
                    return
                }
                var userLang = navigator.language || navigator.userLanguage; 
                this.msg = data.en
                if(userLang && userLang.includes('zh')){
                        this.msg = data.tw
                }
                this.currentState = this.status.beforeSubmit
                this.failHandler.failTimes = 0
            }).catch(()=>{
                this.failHandler.failTimes += 1
                if(this.failHandler.failTimes >= 3){
                    //錯誤3次以上開始每次都加上10秒倒數
                    this.failHandler.countdown = 10 * (this.failHandler.failTimes - 2)
                    let timer = setInterval(() => {
                    this.failHandler.countdown -= 1
                    if(this.failHandler.countdown <= 0){
                        clearInterval(timer)
                    }
                }, 1000);
                }
                this.currentState = this.status.checkFailed
            }).finally(()=>{
                this.pending = false
            })
        },
        submitCode(){
            this.pending = false
             this.coupon = this.coupon.trim()
            let token = this.$store.state.user.token
            if(!token || this.coupon.length==0){
                return
            }
            this.currentState = this.status.redeeming
            console.log(this.coupon,token)
            this.$api.redeemCoupon(this.coupon,token).then(res=>{
                let data = res.data
                if(res.status!=200){
                    this.didReceivedResponse(false,"Something went wrong.\nPlease try again.")
                }
                if(data.error){
                    this.didReceivedResponse(false,data.reason)
                }else{
                    this.didReceivedResponse(true)
                }
            }).catch(()=>{
                this.didReceivedResponse(false,"Something went wrong.\nPlease try again.")
            }).finally(()=>{
                this.pending = false
            })
        },
        didReceivedResponse(success,msg){
            if(success){
                this.currentState = this.status.redeemSuccess
            }else{
                this.currentState = this.status.redeemFailed
                this.msg = msg
            }
        }
    },
}
</script>

<style lang="sass" scoped>
@import "../../sass/color.sass";
.title
    font-size: 2rem
    color: $orange
input,input:focus
    border: none
    outline: none
.input
    background-color: #fff
    border: 1px solid $orange
.main
    background-color: #FFEADA
    border: 3px solid rgb(192, 192, 192)
    .btn
        font-size: 1.3rem
        // box-shadow: 0 -2px 20px #FFEADA
</style>