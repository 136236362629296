<template lang="pug">
    .container.pt-3
        .row.p-3.mb-3.text-center.justify-content-center.align-items-center
            .col-6.col-sm-2 {{$t('label.project')}}
            .col-6.col-sm-2 {{$t('label.name')}}
            .col-2.d-none.d-sm-block {{$t('label.materials')}}
            .col-3.d-none.d-sm-block {{$t('label.description')}}
            .d-none.d-sm-block.col-sm-2 {{$t('label.edit')}}
        .row.p-3.bg-light(v-if="!projects.length")
            .col.d-flex.justify-content-center {{$t('label.emptyProjectList')}}
        .row.p-3.text-center.justify-content-center.align-items-center.projects.border-bottom(v-for="project in projects" :class="[project.deleting?'bg-warning':'bg-light']")
            .col-6.col-sm-2.p-2
                .square
                    .inner
                        img.w-100.h-100(:src="project.image_url")
            .col-6.col-sm-2.text-truncate {{project.name}}
            .col-2.text-truncate.d-none.d-sm-block {{project.material_list?materialsString(project.material_list):""}}
            .col-3.d-none.d-sm-block {{project.description}}
            .col-12.col-sm-2.d-flex.flex-column
                .row
                    .col-6.col-sm-12
                        .w-100.btn.btn-edit(@click="edit(project.id)") {{$t('label.edit')}}
                    .col-6.col-sm-12.mt-sm-1
                        button.w-100.btn.btn-cancel(:disabled="project.deleting" @click="deleteProject(project)") {{$t('button.delete')}}
        infinite-loading(
                    slot="append"
                    @infinite="infiniteHandler")
        
</template>

<script>
export default {
    data(){
        return{
            projects:[],
            currentPage:0,
            per:20,
            token:undefined
        }
    },
    
    beforeMount(){
        this.token = this.$store.state.user.token
        if(!this.token){
            this.$router.push("/")
            return
        }
        
        // for(let i =0;i<10;i++){
        //  this.projects.push(
        //      {public:false,id:i,membership:'golden',name:'project name',liked:false,machine_type:'LC50',author:{name:'author',uuid:'7703C34F-C289-469F-A20E-9B87A39B09DB'},tasks:[{name:'1',material:"",description:"\n\n\n\n\n\n\n"},{name:'2',material:"",description:"\n\n\n\n\n\n\n"},{name:'3',material:"",description:"\n\n\n\n\n\n\n"}]}
        //  )
        // }
        // console.log(this.projects)
    },methods:{
        infiniteHandler($controller){
            this.currentPage += 1
            this.$api.getMyProjects(this.token,this.currentPage,this.per).then(res=>{
                // console.log(res)
                if(!res.data.items||!res.data.items.length){
                    $controller.complete();
                    return
                }
                this.projects = this.projects.concat(res.data.items.map(m=>{
                    m.deleting = false
                    return m
                }))
                $controller.loaded();
            })
        },
        edit(id){
            this.$router.push(`/project/${id}/true`)
        },
        deleteProject(project){
            this.$confirm("Are you sure？").then(()=>{
                let token = this.$store.state.user.token
                if(!token){
                    return
                }
                project.deleting = true
                this.$api.deleteProject(token,project.id).then(res=>{
                    if(res.data.error){
                        project.deleting = false
                        return
                    }else{
                        for(let i=0;i<this.projects.length;i++){
                            if(this.projects[i].id == project.id){
                                this.projects.splice(i,1)
                                break
                            }
                        }
                    }
                })  
            }).catch(()=>{

            })
            
        },
        materialsString(list){
            return list.join(",")
            // let materials = this.$store.state.materialList
            // return list.map(i=>{
            //     let newName=i
            //     materials.forEach(e => {
            //         if(e.listing.english == i){
            //             newName = e.listing.traditional_chinese
            //         }
            //     });
            //     return newName
            // }).join(",")
        }
    }
}
</script>

<style lang="sass" scoped>
.projects
    
</style>