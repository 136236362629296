<template lang="pug">
  .detail.pb-5
    .container-fluid.header
            .content
                .row.pt-3.pb-3
                    .col-4.d-flex.d-flex.align-items-center
                        //- img(src="@/assets/heart.png")
                        img(v-if="project.membership == 'Gold'" src="@/assets/golden.png")
                        img(v-else-if="project.membership == 'Silver'" src="@/assets/silver.png")

                    .col.d-flex.justify-content-center.title.font-weight-bold {{project.name}}
                    .col-4.d-flex.justify-content-end.align-items-end(v-if="editMode")
                        .lc.hover_pointer.mr-2(v-if="editMode")
                            .type.dropdown-toggle(id="lc" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'label-LC35':project.machine_type == 'LC35','label-LC50':project.machine_type == 'LC50'}") 
                                | {{project.machine_type == "Unspecified" ? $t('label.laserModule'):project.machine_type}} 
                            .dropdown-menu(aria-labelledby='lc')
                                .dropdown-item(@click="setLC('LC35')") LC35
                                .dropdown-item(@click="setLC('LC50')") LC50
                        .lc( v-else ,:class="{'label-LC35':project.machine_type == 'LC35','label-LC50':project.machine_type == 'LC50'}") {{project.machine_type != "Unspecified" ? project.machine_type : ""}}
                        .hover_pointer
                            .dropdown-toggle.label-LC35(id="downloadable" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ) 
                                | {{project.downloadable ? $t('utility.public') : $t('utility.private')}} 
                            .dropdown-menu(aria-labelledby='downloadable')
                                .dropdown-item(@click="setDownloadable(true)") {{$t('utility.public')}}
                                    span(v-if="project.machine_type == 'Unspecified'") ({{$t('article.laserModuleNotSet')}})
                                .dropdown-item(@click="setDownloadable(false)")  {{$t('utility.private')}}
                    router-link.col-4.d-flex.justify-content-end.align-items-center(v-else-if="project.author"  :to="getAuthorLink(project.author)")
                        img.rounded-circle(:src="project.author?project.author.photolink:'../assets/member-photo.png'")
                        span.ml-1.text-truncate {{project.author?project.author.name:""}}
    
    .container-fluid      
        .content.pt-3
            .row
                .col-12.col-md-3.d-flex.flex-column
                    .row.mb-1
                        .col-12.p-0
                            //- .btn.btn-edit( @click="download")  下載
                            .btn.btn-edit( @click="download")  {{$t('button.download')}}
                    .row
                        .col-12.square
                            .inner
                                img.w-100.h-100(:src="project.image_url")
                    .row
                        .text-muted {{$t('label.description')}}
                    .row.flex-fill
                        .introduction.col-12.p-2.rounded-lg
                            textarea.w-100.h-100(v-model="project.description" type="text", :disabled="!editMode")
                .col-12.col-md-9
                    .row.p-3
                        .col-3  {{$t('label.task')}}
                        .col-3  {{$t('label.name')}}
                        .col-4  {{$t('label.description')}}
                        .col-2  {{$t('label.material')}}
                    .task_list.border
                        .row.p-3.border(v-for="(task,i) in project.tasks")
                            .col-3  
                                img.w-100.h-100(:src="task.image_url")
                            .col-3
                                input.w-100.h-100(v-model="task.name" type="text", :disabled="!editMode")
                            .col-4
                                textarea.w-100.h-100(v-model="task.description" type="text", :disabled="!editMode")
                            .col-2.dropdown.d-flex.flex-column.justify-content-center(v-if="editMode")
                                button.btn.btn-secondary.dropdown-toggle(:id="'m-'+i" type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
                                    | {{getMaterialName(task.material)}}
                                .dropdown-menu.materialList.border(:aria-labelledby="'m-'+i")
                                    a.dropdown-item(href='#' v-for="material in materialList" @click="setTaskMaterial(task,material.listing.english)") {{material.listing.english}}
                            .col-2.d-flex.align-items-center(v-else)
                                | {{getMaterialName(task.material)}}

                    .row.btn-part.justify-content-end.mt-3(v-if="editMode")
                        // <div class="col-md"></div>
                        .mr-2(v-if="success") {{$t('label.success')}}
                        button.col-md-4.col-12.btn.btn-cancel.m-1(:disabled="pending" @click="cancel") {{$t('button.cancel')}}
                        button.col-md-4.col-12.btn.btn-edit.m-1(:disabled="pending" @click="submit") {{pending?$t('label.pending'):$t('button.submit')}}

                    
</template>

<script>
import {ProjectDownloadPermissionError} from '../utils/error/Error'
import MembershipEnum from '../models/MembershipEnum'
export default {
    props:["id","editMode"],
    data(){
        return{
            success:false,
            pending:false,
            project:{},
            lastDownloadTime:undefined
        }
    },
    beforeMount(){
        this.getProject()
       
    },
    methods:{
            getAuthorLink(author){
                console.log(author)
                if(author.name == "Cubiio Official"){
                    return "/projects/official"
                }else{
                    return `/profile/${author.uuid}`
                }
            },
        setDownloadable(yes){
            if(yes && this.project.machine_type == 'Unspecified'){
                return
            }
            this.project.downloadable = yes
        },
        getProject(){
            let token =  this.$store.state.user ? this.$store.state.user.token : undefined
        this.$api.getProjectDetail(this.id,token).then(res=>{
            // console.log(res)
            this.project = res.data
             if(this.editMode && this.project.author.uuid != this.$store.state.user.user.id){
                    this.$router.push("/project/"+this.id)
                }
        })
        },
        handleDownloadPermission(res){
            if(!res.data){
                    throw new Error(this.$t('article.otherError'));
            }else{
                let downloadable = res.data.downloadable
                let reason = res.data.reason
                if(!downloadable){
                    if(!reason){
                        throw new Error(this.$t('article.otherError'))
                    }else{
                        let error = new ProjectDownloadPermissionError(reason)
                        for(let key in error.errorMessage.localizedArg){
                            error.errorMessage.localizedArg[key] = this.$t(error.errorMessage.localizedArg[key])
                        }
                        throw new Error(this.$t(error.errorMessage.localizedMainMessage,error.errorMessage.localizedArg));
                    }
                }
            }
            return true
        },
       download(){
           let token =  this.$store.state.user ? this.$store.state.user.token : undefined
           let pid = this.project.id
           this.$api.chekcProjectDownloadPermission(token,pid)
           .then(res=>this.handleDownloadPermission(res))
           .then(downloadable=>{
               console.log({downloadable})
                if(!downloadable){
                    return
                }
                if(this.$appBridge.isApp()){
                    this.throttleDownload(pid)
                }else{
                    this.$alert(this.$t('article.downloadOnlyInApp'))
                }
           }).catch((error)=>{
               console.dir(error.message)
               this.$alert(error.message ?? error)
           })
       },
       throttleDownload(pid){
           if(!this.lastDownloadTime){
                this.lastDownloadTime = new Date().getTime()
                this.$appBridge.downloadProject(pid)
           }else{
                let now = new Date().getTime()
                let downloadElapsedTime = (now - this.lastDownloadTime)/1000 
                if(downloadElapsedTime > 60){
                    this.lastDownloadTime = now
                    this.$appBridge.downloadProject(pid)
                }else{
                    this.$confirm(this.$t('article.downloadAgain')).then(()=>{
                        this.lastDownloadTime = now
                        this.$appBridge.downloadProject(pid)
                    }).catch((error)=>{
                        console.error(error)
                    })
                }
           }
       },
       cancel(){
            // this.$router.push("/project/"+this.id)
            this.$router.go(-1)
       },
       submit(){
           let token = this.$store.state.user.token
           if(!token){
               this.$router.push("/project/"+this.id)
               return
           }
           this.pending = true
        //    console.log(this.project)
           let update = {
               machine_type:this.project.machine_type,
                name:this.project.name,
                description:this.project.description || "",
                downloadable:this.project.downloadable,
                tasks:[]
            }
            for(let i in this.project.tasks){
                let task =  this.project.tasks[i]
            // console.log(task)
            update.tasks.push({
                id:task.id,
                description:task.description || "",
                name:task.name || "",
                material:task.material
            })
            }
           this.$api.updateProject(token,this.project.id,update).then(()=>{
               this.getProject()
               setTimeout(() => {
                   this.success = true
                this.pending=false
               }, 1000);
           })
       },
       setLC(lc){
           this.project.machine_type = lc
       },
       setTaskMaterial(task,material){
            task.material = material
        },
        getMaterialName(material){
            return material
            // let res = this.materialList.filter(m=>{
            //     return m.listing.english == material
            // })
            // if(res.length){
            //     return res[0].listing.traditional_chinese
            // }else{
            //     return  material
            // }
        },isGreaterMembership(){
            
            if(!this.$store.state.user){
                return false
            }
            let selfMemberShip = this.$store.state.user.user.membership.toLowerCase()
            //    console.log(this.project.membership.toLowerCase(),selfMemberShip)
            switch(this.project.membership.toLowerCase()){
                case  MembershipEnum.gold:
                    return selfMemberShip ==  MembershipEnum.gold
                case MembershipEnum.silver:
                    return selfMemberShip ==  MembershipEnum.gold || selfMemberShip ==  MembershipEnum.silver
                default:
                    return true
            }
        },membershipName(membership){
            return this.$t('label.'+membership.toLowerCase())
        },
    },
    computed:{
        materialList(){
            return this.$store.state.materialList
        }
    }
}
</script>

<style lang="sass" scoped>
.task_list
    height: 400px
    overflow: scroll
.detail 
    position: relative
    .content
        padding-left: 10%
        padding-right: 10%
    .header
        background-color: #E1E1E1
    .title
        font-size: 1.5rem
    img
        height: 30px
        width: 30px
.introduction
    min-height: 40px
    background-color: #E1E1E1
    border: 1px solid #7E7E7E
.materialList
    height: 100px
    overflow: scroll
</style>