<template lang="pug">
  .container
    .row.py-3
        .col-12.col-md-3
            .bg-light.h-100.container
                .row
                    h1.col {{groupName}}
                .row
                    .col.border.embed-responsive.embed-responsive-1by1
                        img.cover-img.embed-responsive-item(:src="group.image_url")
        .col-12.col-md-9
            .projects
                .row 
                    h3.col Projects 
                ProjectList(:projects="projects")
            .doodles.mt-3
                .row 
                    h3.col Doodles
                .row
                    .col-xl-2.col-lg-3.col-md-3.col-sm-4.col-12.p-2(v-for="doodle in doodles" )
                        ImageCard(:item="doodle")
</template>

<script>
import {getGroupDetail} from '../../api/general'
import ProjectList from '../ProjectList'
import ImageCard from '../ImageCard'
// import Project from '../../models/Project'
export default {
    props:["id"],
    components:{ProjectList,ImageCard},
    data(){
        return{
            projects:[],
            doodles:[],
            group:{}
        }
    },
    mounted(){
        this.getGroupDetail()
    },
    methods:{
        getGroupDetail(){
            getGroupDetail(this.id).then(res=>{
                let data = res.data
                console.dir(data)
                this.group = data
                let projectList = data.projects || []
                this.projects = projectList
                let doodleList = data.doodles || []
                this.doodles = doodleList.map(el=>{
                    return {
                        image_url : el.image_url,
                        title: el.name,
                        membership: el.membership
                    }
                })
            })
        }
    },
    computed:{
        groupName(){
            return this.group.title ? 
            (this.$store.getters.isLanguageChinese ? this.group.title.tw :this.group.title.en ):
            ""
        }
    }
}
</script>

<style lang="sass" scoped>
.cover-img
    object-fit: contain
</style>