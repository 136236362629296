<template lang="pug">
  .container
    .list
        .head.row.py-2
            .col 訂閱項目
            .col 訂閱生效日期
            .col 扣款日期
            .col 扣款結果
        .row.py-2.border(v-for="item in subscriptions") 
            .col {{item.name}}
            .col {{item.startDate}}
            .col {{item.renewDate}}
            .col 
                button(v-if="item.isOngoing" @click="setSuspend(true)") 取消訂閱
                button(v-else-if="item.isSuspended" @click="setSuspend(false)") 取消訂閱
                div(v-else) {{item.debitResultString}}
</template>

<script>
import {DebitResultEnum,SubscribeHistoryData,SubscriptionSuspendStatus} from '../../models/Subscription'
import {history,setSubscriptionSuspendStatus } from '../../api/payment'
export default {
    data(){
        return{
            page:{
                total:0,
                page:1,
                per:20
            },
            subscriptions:[
                // new SubscribeHistoryData({
                //     name:"金卡30",
                //     startDate:new Date().addMonths(2).format("yyyy-MM-dd"),
                //     debitDate:new Date().addMonths(2).format("yyyy-MM-dd"),
                //     debitResult:DebitResultEnum.ongoing
                // }),
                // new SubscribeHistoryData({
                //     name:"銀卡30",
                //     startDate:new Date().addMonths(1).format("yyyy-MM-dd"),
                //     debitDate:new Date().addMonths(1).format("yyyy-MM-dd"),
                //     debitResult:DebitResultEnum.deducted
                // }),
                // new SubscribeHistoryData({
                //     name:"金卡30",
                //     startDate:new Date().format("yyyy-MM-dd"),
                //     debitDate:new Date().format("yyyy-MM-dd"),
                //     debitResult:DebitResultEnum.canceled
                // }),
            ]
        }
    },
    mounted(){
       this.getHistory()
    },
    methods:{
        getHistory(){
            this.subscriptions.length = 0
            history().then(res=>{
            if(!res.data){return}
            let {data,page} = res.data
            console.log(data)
            this.page = page
            this.subscriptions = data.map(item=>{
               return new SubscribeHistoryData({
                    name:item.description,
                    startDate:item.subscribed_date,
                    renewDate:item.renew_date,
                    status:item.status
                })
            })
        })
        },
        setSuspend(isOngoing){
            let title = isOngoing?"確定要停止訂閱嗎？":"確定要恢復訂閱嗎？"
            let status = SubscriptionSuspendStatus.cancel
            if(!isOngoing){
                status = SubscriptionSuspendStatus.resume
            }

            this.$confirm(title).then(()=>{
                setSubscriptionSuspendStatus(status).then(res=>{
                        console.log(res.data)
                        this.getHistory()
                    })
            })
    
        }
    },
    computed:{
        debitResultEnum(){return DebitResultEnum}
    }
}
</script>

<style lang="sass" scoped>

</style>