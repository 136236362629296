<template lang="pug">
    .container.col-6
        .title.font-weight-bold.mt-3.mb-2  Upload Doodles

        .form-group
            label.form__label {{$t("label.name")}}
            input.form__input.form-control(v-model.trim="$v.name.$model")
            .small.text-danger(v-if="!$v.name.required") {{$t("label.name")}} {{$t("error.required")}}
        .form-group
            label.form__label  {{$t("label.description")}} 
            input.form__input.form-control(v-model.trim="$v.description.$model")
            .small.text-danger(v-if="!$v.description.required")  {{$t("label.description")}} {{$t("error.required")}}
        .form-group
            label.form__label Cover Image
            input.form__input.form-control(type="file" accept="image/png, image/jpg, image/jpeg" @change="imgChange")
            .small.text-danger(v-if="!$v.img.required") Image is {{$t("error.required")}}
        .form-group
            label.form__label File
            input.form__input.form-control(type="file" accept=".txt"  @change="fileChange")
            .small.text-danger(v-if="!$v.file.required") File is {{$t("error.required")}}
    
        .btn.btn-edit.mt-3.ml-2(type="submit" @click="uploadDoodleBtn") {{$t("button.upload")}}
        p.typo__p(v-if="submitStatus === 'OK'") 
        p.typo__p(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
        p.typo__p(v-if="submitStatus === 'PENDING'") Sending...





</template>
<script>
import ImageCard from './ImageCard.vue'
import {uploadDoodle} from '../api/doodle'
import {required} from 'vuelidate/lib/validators'
export default {
    components:{
        ImageCard
    },
    data(){
        return{
                name:undefined,
                description:undefined,
                file:undefined,
                img:undefined,
                formData:new FormData(),
                submitStatus:null,
                doodles:[]
        }
        
    },
    validations: {
        name: {required},
        description: {required},
        file: {required},
        img: {required},
    },
    
    beforeMount(){


    },methods:{
        imgChange(e){
            this.img = e.target.files[0]
            this.formData.append('image', e.target.files[0]) //放進上傳的檔案         
        },
         fileChange(e){
            this.file = e.target.files[0]
            this.formData.append('file', e.target.files[0]) //放進上傳的檔案
        },
        uploadDoodleBtn(){
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'
                this.formData.append('name',this.name)
                this.formData.append('description',this.description)
                this.formData.append('who_can_download',"Bronze")
                for (var pair of this.formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
                } 
                uploadDoodle(this.formData).then(res=>{
                    console.log(res)
                    if (res.status == 200){
                        this.$alert(this.$t('title.success'))
                        this.submitStatus = 'OK'
                        this.name = undefined
                        this.description = undefined
                        this.file = undefined
                        this.img = undefined
                    }
                    
                }).catch((e)=>{
                    console.log(e)
                })
            }
        },

    }
}
</script>

<style lang="sass" scoped>
.projects
</style>