import Vue from 'vue'

const install = function() {
    let bridge = {
        downloadProject(id){
            if(window.webkit){
                window.webkit.messageHandlers["downloadProject"].postMessage(id)
            }else if (window.AndroidBridge) {
                window.AndroidBridge.downloadProject(id);
            }
        },
        loaded(){
            if(window.webkit){
                window.webkit.messageHandlers['loaded'].postMessage("")
            }else if (window.AndroidBridge) {
                window.AndroidBridge.webpageLoaded();
            }
        },
        logout(){
            if(window.webkit){
                window.webkit.messageHandlers['logout'].postMessage("")
            }else if (window.AndroidBridge) {
                window.AndroidBridge.logout();
            }
        },
        login(token){
        
            if(window.webkit){
                window.webkit.messageHandlers['login'].postMessage(token)
            }else if (window.AndroidBridge) {
                window.AndroidBridge.login(token);
            }
        },
        isApp(){
            return window.webkit != undefined || window.AndroidBridge != undefined
        }
    }
    Vue.prototype.$appBridge = bridge
}
export default { install }