<template lang="pug">
.container(v-if="popupVisible")
  .modal-mask
    .modal-dialog.modal-dialog-scrollable(role="document",:class="{'modal-lg':largeSize}")
      .modal-content.max-size-wrapper
        .modal-header
          h5.modal-title 
            slot(name="title")
          button.close(
            type="button",
            data-dismiss="modal",
            aria-label="Close",
            @click="cancel"
          )
          span(aria-hidden="true") &times;
        .modal-body.h-100.overflow-auto
            slot(name="body")
        .modal-footer
            slot(name="footer")
                button.btn.btn-secondary(
                    type="button",
                    data-dismiss="modal",
                    @click="cancel"
                ) Cancel
                //- button.btn.btn-primary(type="button", @click="submit") {{submitButtonText}}
</template>

<script>
export default {
  props: {
      "largeSize":{
        type:Boolean,
        default:false
    },
    "popupVisible":{
        type:Boolean,
        default:false
    },
    "submitButtonText":{
        type:String,
        default:"Submit"
    }
  },
  data() {
    return {
      data: {
        // popupVisible:false,
        id: undefined,
        name: undefined,
        email: undefined,
        description: undefined,
        uploadlimit: undefined,
        downloadlimit: undefined,
        image_link: undefined,
        image_file: undefined,
      },
    };
  },
  mounted() {
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="sass">
.max-size-wrapper
    // max-height: 500px
.input-title
    border: 1px solid gray
.couponList
    max-height: 400px
.modal-mask
    position: fixed
    z-index: 9998
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, .5)
    display: table
    transition: opacity .3s ease

.modal-wrapper
    display: table-cell
    vertical-align: middle
</style>
