<template lang="pug">
    .d-flex.overflow-auto.flex-nowrap.pb-1
        slot(name="content")
</template>

<script>
export default {

}
</script>

<style  scoped>
.d-flex{
    overscroll-behavior-x: contain;
    -webkit-user-select:none;
-moz-user-select:none;
-o-user-select:none;
user-select:none;
}
.d-flex:hover::-webkit-scrollbar{
 height: 1em;
}
::-webkit-scrollbar {
    display: block;
}
/* width */
::-webkit-scrollbar {
    width: 0.3em;
  height: 0.3em;
  /* height: 10px; */
}
/* Track */
::-webkit-scrollbar-track {
  background: #ff6a001e;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 2px;
  background: #FF6B00;
}
</style>