<template lang="pug">
    .home
        .container-fluid(v-if="banners.length")
            .banner.row
                .banner-wrapper
                    Carousel.h-100.w-100
                        template(v-slot ) 
                            .carousel-item.h-100(v-for="(banner,i) in banners" :class="{active:i == 0}")
                                a(v-if="banner.redirectLink" :href="banner.redirectLink")
                                    img(:src="banner.imageLink" class="d-block w-100" alt="...")
                                img(v-else :src="banner.imageLink" class="d-block w-100" alt="...")
        .offical.projects.container-fluid.pb-2(v-if="officialProjects && officialProjects.length")
            .p20
                .title.font-weight-bold.mt-3.mb-2 {{$t("title.cubiio_design")}}
                    .btn.btn-edit.ml-2(@click="searchOfficial") {{$t("button.more")}}
                .row
                    HorizontalScrollView
                        template(v-slot:content)
                            .col-xl-2.col-lg-3.col-md-3.col-sm-4.col-6.p-2(v-for="project in officialProjects" )
                                ProjectItem(:project="project" :allowReport="false")
        .offical.container-fluid.pb-2(v-if="officialDoodles && officialDoodles.length")
            .p20
                .title.font-weight-bold.mt-3.mb-2 {{$t("label.officialDoodles")}}
                    .btn.btn-edit.ml-2(@click="searchDoodles") {{$t("button.more")}}
                .row
                    HorizontalScrollView
                        template(v-slot:content)
                            .col-xl-2.col-lg-3.col-md-3.col-sm-4.col-6.p-2(v-for="doodle in officialDoodles" )
                                ImageCard(:item="doodle")
        .container-fluid.projects.mt-3    
            .p20
                #user-projects.title.font-weight-bold {{$t("title.user_projects")}}
                ProjectList(:projects="projects" :allowReport="true")
            Paginator(:currentPage="currentPage" :pages="pages" @goToPage="goToPage")
        //- router-link.version(to="/test/subscription") version: 2

</template>
<script>
import BannerData from '../models/BannerData'
import ProjectList from './ProjectList'
import Paginator from './Paginator'
import ProjectItem from './ProjectItem'
import ImageCard from './ImageCard'
import HorizontalScrollView from './HorizontalScrollView.vue'
import Carousel from './Carousel.vue'
import Banner from '../api/banner'

export default {
    components:{    
        ProjectList,
        HorizontalScrollView,
        Carousel,
        ProjectItem,
        Paginator,
        ImageCard,
},
data() {
    return {
        banners:[],
        officialProjects:[],
        officialDoodles:[],
        projects:[],
        currentPage:0,
        // per:2,
        per:24,
        pages:0,
        eventPopupDetail:{
            popupVisible:undefined,
            largeSize:true,
            eventID:1234567
        }
    }
},
beforeMount(){
    this.goToPage(1)
    this.officialProjects = undefined
        let token =  this.$store.state.user? this.$store.state.user.token:undefined
    this.$api.getPromotedProjects(token).then(res=>{
        this.officialProjects = res.data.sort((a,b)=>{
            return new Date(b.created_time).getTime() - new Date(a.created_time).getTime()
        })
    })
    this.$api.getPromotedDoodles(token).then(res=>{
        this.officialDoodles = res.data.sort((a,b)=>{
            return new Date(b.created_time).getTime() - new Date(a.created_time).getTime()
        }).map(el=>{
            let item = {}
            item.image_url = el.image_url
            item.title=el.name
            // item.detail = el.description
            item.membership=el.who_can_download
            // item.onclick=function(){
            //     this.$copyText(item.title).then(()=> {
            //         this.$alert(this.$t('article.copied'))
            //         }, ()=> {
            //         this.$alert(this.$t('article.copy_fail'))
            //     })
            // }.bind(this)
            return item
        })
        this.getBanners()
    })
},methods:{
    getBanners(){
        this.banners.length = 0
        Banner.getWebBanners().then(res=>{
            // console.log(res.data)
            if (res.data.images){
                let images = res.data.images
                if (this.$store.getters.currentLang.key == "zh"){
                    for( let i in images){
                        let imageData = images[i]
                        let newBannerData = new BannerData({
                        imageLink:imageData.link,
                        redirectLink:imageData.url
                    }) 
                    this.banners.push(newBannerData)
                    }
                    // console.log(this.banners)
                }else if (this.$store.getters.currentLang.key == "en"){
                    for( let i in images){
                        let imageData = images[i]
                        let newBannerData = new BannerData({
                        imageLink:imageData.link_en,
                        redirectLink:imageData.url_en
                    }) 
                    this.banners.push(newBannerData)
                    }
                }
            }
            
        })
    },
    searchDoodles(){
        this.$router.push("/doodles")
    },
    searchOfficial(){
        this.$router.push("/projects/official")
    },
    goToPage(n){
        let token =  this.$store.state.user? this.$store.state.user.token:undefined
        this.projects = undefined
        this.$api.searchAllProjects({
        page:n,
        per:this.per,
        range:["user"],
        sortBy:'c',
        searchFrom:"None"
    },
    token
    ).then(res=>{
        // console.log(res.data.items)
        this.projects = res.data.items
        this.pages = Math.ceil(res.data.page.total /  res.data.page.per)
        this.currentPage = res.data.page.page
    })
    }
}
}
</script>
<style lang="sass" scoped>
@import "../sass/color.sass";
.p20
    padding-left: 5%
    padding-right: 5%
    // padding-left: 10%
    // padding-right: 10%

.banner
    position: relative
    max-width: 1440px
    padding-top: 20.83%
    //   height: 300px
    margin: 0 auto
    .banner-wrapper
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
    img
        object-fit: contain
        height: 100%
        width: 100%


.offical 
    widows: 100%
    overflow: scroll
    background-color: $gray-1
    &.projects
        background-color: $gray-2
.official-list
    overflow: scroll

.pagination
    input
        width: 40px
.version
    color: white
</style>