<template lang="pug">
    .home
        .container-fluid.projects.pt-5 
            .p20#search-projects
                .row
                    .col.title.font-weight-bold {{$t('label.officialDoodles')}}
                    .col.input-group
                        .input-group-prepend
                            select.btn.btn-outline-secondary(data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' v-model="searchOptions.who_can_download") 
                            
                                option.dropdown-item(:value="undefined") {{$t('label.all')}}
                                option.dropdown-item(value="g") {{$t('label.golden')}}
                                option.dropdown-item(value="s") {{$t('label.silver')}}
                                option.dropdown-item(value="b") {{$t('label.basic')}}
                        input.form-control(type='text' aria-label='' v-model="searchOptions.searchTerm")
                        .input-group-append
                            button#button-addon2.btn.btn-outline-secondary(type='button' @click="search") {{$t("button.search")}}


                .row(v-if="!searching")
                    .col-6.col-md-3.col-lg-2.mt-3(v-for="doodle in doodles")
                        ImageCard(:item="doodle")
                .row.justify-content-center(v-else)
                    | {{$t('label.loading')}}
            Paginator(:currentPage="pageMetadata.page" :pages="pages" @goToPage="getDoodles")
</template>

<script> 
import ImageCard from '../../components/ImageCard'
import Paginator from '../../components/Paginator'
export default {
    components:{ImageCard,Paginator},
    data() {
        return {
            doodles:[],
            pageMetadata:{
                page:1,
                per:24,
                total:0
            },
            searchOptions:{
                searchTerm:"",
                who_can_download:undefined
            },
            searching:false
        }
    },
    mounted() {
        this.getDoodles(1)
    },
    methods: {
        search(){
            this.pageMetadata.page = 1
            this.getDoodles(1)
        },
        getDoodles(page){
            this.searching = true
        let token =  this.$store.state.user? this.$store.state.user.token:undefined
            this.$api.getDoodles(token,page,this.pageMetadata.per,this.searchOptions.searchTerm,this.searchOptions.who_can_download).then(res=>{
                console.dir(res)
                this.pageMetadata.page = res.data.page.page
                this.pageMetadata.total = res.data.page.total
                this.doodles = res.data.doodles.map(el=>{
                    let item = {}
                    item.image_url = el.image_url
                    item.title=el.name
                    // item.detail = el.description
                    item.membership=el.who_can_download
                    // item.onclick=function(){
                    //     this.$copyText(item.title).then(()=> {
                    //         this.$alert(this.$t('article.copied'))
                    //         }, ()=> {
                    //         this.$alert(this.$t('article.copy_fail'))
                    //     })
                    // }.bind(this)
                    return item
                })
                // this.pageMetadata.page = res.
            }).finally(()=>{
                this.searching = false
            })
        }
    },
    computed: {
        pages(){
            return parseInt(Math.ceil(this.pageMetadata.total/this.pageMetadata.per))
        }
    },
}
</script>

<style>

</style>