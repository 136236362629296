<template lang="pug">
  .wrapper.d-flex.justify-content-center.align-items-center(:style="{background:backgroundColor,zIndex:zIndex}")
    slot
</template>

<script>
export default {
    props:{
        zIndex:{
            type:Number,
            default:1050
        },
        backgroundColor:{
            type:String,
            default: "rgba(0, 0, 0, 0.7)"
        }
    }
}
</script>

<style lang="sass" scoped>
.wrapper
    position: fixed
    width: 100vw
    height: 100vh
    top: 0
    left: 0
</style>