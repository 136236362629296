import instance from './instance'

export function refreshToken(token){
    return instance
    .post(`/api/v0/token/refresh`,{token})
  }

export function loginByToken(token){
    let config = {
      headers: {
      }
    }
    if(token){
      config.headers= {
        'Authorization': 'Bearer ' + token
      }
    }
    
    return instance
    .get("/web/user/info",config)
  }