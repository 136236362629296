<template lang="pug">
    .cupon.w-100
        .container-fluid.nav-bar.bg-light.pb-3.pt-3
            ul.nav.row
                li.nav-item(v-for="item in navItems")
                    router-link.nav-link(:to="item.to" :class="{'border-bottom':isSelected(item.to)}") {{item.name}}
        .mt-3
            router-view
</template>

<script>
export default {
    data() {
        return {
             navItems:[
                {
                    name:this.$t('label.redeem'),
                    to:'/me/coupon/redeem'
                },
                {
                    name:this.$t('label.history'),
                    to:'/me/coupon/history'
                }
            ]
        }
    },methods: {
         isSelected(link){
                return link==this.$router.currentRoute.path
        }
    },
}
</script>

<style lang="sass">
.nav-bar
    padding-left: 10%
    padding-rigth: 10%
</style>