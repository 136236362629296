<template lang="pug">
  .container-fluid.d-flex.align-items-stretch.flex-column
    .row.w-100.justify-content-center.pt-5
        .col-lg-8.col-md-10.col-12.p-0
            SubscriptionStepNavigator(currentStepKey="4")
    .d-flex.flex-column.justify-content-center.align-items-center.wrapper.flex-fill
        h3(v-if="success") 感謝您的訂購
        h3(v-else) 付款失敗
        button.btn.btn-edit.px-5.mt-5(@click="goHome")
            span 返回首頁
        button.btn.btn-edit.bg-light-orange.px-5.mt-3(@click="gotoHistory")
            span 訂閱紀錄
                

</template>

<script>
import SubscriptionStepNavigator from "./SubscriptionStepNavigator";
export default {
    props:["success"],
  components: { SubscriptionStepNavigator },
  methods:{
      goHome(){
          this.$router.push("/")
      },
      gotoHistory(){
          this.$router.push("/me/subscribe/history")
      }
  }
}
</script>

<style lang="sass" scoped>
.bg-light-orange
    background-color: #FFDBC1
    border: 1px solid #FF6B00
    color:  #FF6B00
button > span
    font-size: 1.5rem
</style>