import axios from "axios";
import https from 'https'
let protocol =  window.location.protocol
let host = window.location.host
// console.log("current protocol "+ protocol );
// console.log("current port "+ port );
// console.log("current process.env.NODE_ENV "+ process.env.NODE_ENV );
// const baseURL = process.env.NODE_ENV === "development" ? "/api" : "https://211.75.169.51:" + port + "/v0"
const baseURL = process.env.NODE_ENV === "development" ? "/api" :  protocol+"//" + host

// const baseURL = protocol+"//" + host + "/v0"

var instance = axios.create({
  baseURL,
  timeout: 15 * 1000,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  changeOrigin: true,
});
const install = function(Vue) {
    const api ={
      loginByToken(token){
        let config = {
          headers: {
          }
        }
        if(token){
          config.headers= {
            'Authorization': 'Bearer ' + token
          }
        }
        
        return instance
        .get("/web/user/info",config)
      },
    login(email,password,token){
        // console.log("call api login")
        let auth = btoa(email+":"+password)
        let config = {
            headers: {
              'Authorization': 'Basic ' + auth
            },
            data:{
                token
            }
          }
          return instance
        // .post("/web/user/signin",config)
        .post("/web/user/login",{},config)
    },
    register(nickname,email,password,code){

        return instance
        .post("/web/user/signup",{email,nickname,password,code})
    },
    sendConfirmEmail(email){
        return instance
        .post("/web/user/email/send/validation",{email})
    },
    // changePassword(email,password){},
    forgetPassword(email){

        return instance
        .post("/web/user/email/forget/password",{email})
    },
    emailExistCheck(email){
        return instance
        .post("/web/user/email/exist",{email})
    },
    nicknameExistCheck(nickname){
        return instance
        .post("/web/user/nickname/exist",{nickname})

    },
    killSelf(token){
        // console.log(token)
        let config = {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }
        return instance
        .delete("/web/user",config)
    },
    searchAllProjects(option,token){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .post("/web/project/search",option,config)
    },
    getMyProjects(token,page,per){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/project/selfworks/search?page=${page}&per=${per}`,config)
    },
    getPromotedProjects(token){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/project/promoted`,config)
    },
    getProjectDetail(id,token){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/project/${id}`,config)
    },
    getUserProject(id,page,per,token){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/user/${id}/artwork/search?page=${page}&per=${per}`,config)
    },
    getMaterials(){
      return instance
      .get("/api/v0/material/list")
    },
    updateProject(token,id,project){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .put(`/web/project/${id}`,project,config)
    },
    updateUser(token,user){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .put(`/web/user`,user,config)
    },
    updateUserPhoto(token,file){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }

      let formData = new FormData();

      formData.append("document", file);
      return instance
      .put(`/api/v0/user/image`,formData,config)
    },
    deleteProject(token,id){
      let config = {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      return instance
      .delete(`/web/project/${id}`,config)
    },
    getNationList(token){

      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/api/v0/nation/list`,config)
    },
    getUserProfile(id){
     
      return instance
      .get(`/web/user/${id}`)
    },
    getUserDownloadedProjects(token,page,per){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/project/downloaded/search?page=${page}&per=${per}`,config)
    },
    getUserLikedProjects(token,page,per){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/project/liked/search?page=${page}&per=${per}`,config)
    },
    setLikeProject(token,projectId,like=true){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      if(like){
        return instance
        .post(`/web/project/${projectId}/like`,{},config)
      }else{
        return instance
        .delete(`/web/project/${projectId}/like`,config)
      }
    },
    getCouponDescription(code,token){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/voucher/description/${code}`,config)
    },
    redeemCoupon(code,token){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .post(`/web/voucher/redeem`,{token:code},config)
    },
  
    
    getDoodles(token,page,per,searchTerm,whoCanDownload){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/doodle/list?page=${page}&per=${per}&searchTerm=${searchTerm}&who_can_download=${whoCanDownload}`,config)
    },
    getPromotedDoodles(token){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/doodle/promoted`,config)
    },
    chekcProjectDownloadPermission(token,pid){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/web/project/${pid}/download/check`,config)
    },
    refreshToken(token){
      return instance
      .post(`/api/v0/token/refresh`,{token})
    },
    sendTapPayPrime(token,cardResponseData){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .post(`/tappay/v0/prime`,cardResponseData,config)
    },
    sendTapPayPrimeError(token,{status,msg}){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .put(`/tappay/v0/prime/error`,{status,msg},config)
    },
    getPaymentPlan(token){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/order/upgrade/plan`,config)
    },
    getTermsLink(token,query){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      let encoded = encodeURI(`/tappay/v0/terms/${query.langId}/${query.version}`)
      return instance
      .get(encoded,config)
    },
    getSubscriptionPlan(token,planId){
      let config = {
        headers: {
        }
      }
      if(token){
        config.headers= {
          'Authorization': 'Bearer ' + token
        }
      }
      return instance
      .get(`/subscription/plan/${planId}`,config)
    }
    }
    Vue.prototype.$api = api
}
export default { install }