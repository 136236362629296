import instance from './instance'
import {SubscriptionSuspendStatus} from '../models/Subscription'
export function getPaymentTool(){
    return instance
    .get("/api/v0/paytools")
}
export function fetchLoveCodes(){
    return instance
    .get("/order/charity/list")
}
export function history(){
    return instance
    .get("/subscription/history")
}
export function setSubscriptionSuspendStatus(status = SubscriptionSuspendStatus.cancel){
    return instance
    .post(`/subscription/status/${status}`)
}

export function getPaypalPlanId(plan_id,token){
    return instance
    .post(`/paypal/plan/id`,{plan_id,token})
}
export function reportPaypalResult(payload){
    return instance
    .post(`/paypal/subscription/report`,payload)
}
export function getPaymentPlan(env){
    let apiTail = env == "paypal" ? "/usd" : ""
    return instance
    .get(`/order/upgrade/plan${apiTail}`)
    
}