 let COUPON_TYPE_ENUM = Object.freeze({
    "discount": "d",
    "percentage": "p"
})
 function discountResult(type, originalPrice = 0,discount = 0) {
    if(originalPrice <= 0){return 0}
    switch (type) {
        case COUPON_TYPE_ENUM.discount: {
            return originalPrice - discount
        }
        case COUPON_TYPE_ENUM.percentage: {
            return Math.ceil(originalPrice*(discount/100))
        }
    }
}
 function discountDescription(type, originalPrice = 0,discount = 0) {
    if(originalPrice <= 0){return "ERROR"}
    switch (type) {
        case COUPON_TYPE_ENUM.discount: {
            return `${originalPrice} - ${discount} = ${originalPrice - discount}`
        }
        case COUPON_TYPE_ENUM.percentage: {
            let result = Math.ceil(originalPrice*(discount/100))
            return `${originalPrice} x ${(discount/100)} = ${result}`
        }
    }
}
export default {
    COUPON_TYPE_ENUM,
    discountResult,
    discountDescription
}