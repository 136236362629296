import instance from './instance'

export function getGroupDetail(id){
    return instance
    .get(`/api/v0/group/${id}`)
  }

export function followAuthor(id){
  return instance.post(`/web/user/${id}/follow/`)
}
export function unfollowAuthor(id){
  return instance.delete(`/web/user/${id}/follow/`)
}