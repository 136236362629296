
<template lang="pug">
    #steper.row.py-3
        .col(v-for="step in steps" :key="step.key")
            div(:class="{selected:(step.key==currentStepKey)}") {{step.title}}
</template>

<script>
export default {
    props:{
        steps:Array,
        currentStepKey:String
    },
    data() {
        return {
            // steps:[],
            // currentStepKey:""
        }
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    },
}
</script>

<style lang="sass" scoped>
#steper
    > div > div
        height: 50px
        color: #939191
        background: #E1E1E1;
        border-radius: 5px;
        display: flex
        justify-content: center
        align-items: center
        cursor: default
    .selected
        background: #FF6B00
        color: #FFFFFF

#buttonSet
    > div > div
        height: 50px
        display: flex
        justify-content: center
        align-items: center
        border: 2px solid #FF6B00;
        border-radius: 5px;
        cursor: pointer
    .backButton
        background: #FFFFFF;
        color: #FF6B00
    .nextButton
        background: #FF6B00;
        color: #ffffff
.golden
    background: #FFD028 !important
    .option > div
        background: #FFF4CB
#products
    > div
        margin: 10px auto
        border: 4px solid #FFD028
        box-sizing: border-box
        border-radius: 10px
        text-align: center
        background: #FFFFFF
        
        .des
            .title
                font-size: 1.5rem
                font-weight: bold
            .button
                height: 55px
                font-size: 1.2rem
                color: #FF6B00
                background: #FFFFFF;
                border: 2px solid #FF6B00;
                box-sizing: border-box;
                border-radius: 5px;
        .option > div
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
            border-radius: 5px
            font-weight: bold
            font-size: 1.2rem
            .price
                font-size: 2rem
            .button
                font-size: 1.2rem
                height: 60px
                background: #FFD028
.product-icon
    margin: auto
    width: 30px !important
    height: 30px !important

#privacy
    > div
        height: 40vh

#payment
    .block
        background: #f7f7f7
        > div
            margin: 10px auto
            .input
                background: #FFFFFF;
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 10px
            &:nth-child(1)
                margin-top: 0
            &:last-child
                margin-bottom: 0
    .title
        font-weight: bold
        font-size: 1.5rem
        margin: 10px auto
        .subtext
            font-size: 1.2rem
            font-weight: normal
    .invoice
        > div
            margin: 0

.credit-icon
    width: 45px
    height: auto
    background: lightgray

#result
    .title
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 47px;
        text-align: center;
        margin-bottom: 10px
    .button
        color: #FF6B00
        background: #FFDBC1;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 35px;
        border: 2px solid #FF6B00;
        box-sizing: border-box;
        border-radius: 5px;
    .background-FF6B00
        color: white
        background: #FF6B00

.cover
    width: 100vw
    height: 100%
    background: rgba(35, 35, 44, 0.9);
    > div
        background: #FFFFFF;
        border-radius: 15px;
    .title
        position: relative
        height: 40px
        font-size: 1.6rem
        font-weight: bold
    .button-close
        position: absolute
        top: 0
        right: 0
        &:hover
            background: lightgray

#pro-description
    .body
        max-height: 50vh

#sub-error
    .button
        color: white
        background: #FF6B00
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 35px;
        box-sizing: border-box;
        border-radius: 5px;
    .background-00B900
        color: white
        background: #00B900
    .body > div
        color: #EA4335
        margin: 10px auto
.basic
    border-color: rgb(232, 232, 232) !important
.tpfield 
    background-color: #fff
    height: 40px
    width: 300px
    border: 1px solid gray
    margin: 5px 0
    padding: 5px


</style>