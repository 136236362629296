
import Home from './components/Home'
import Register from './components/Register'
import UserPage from './components/UserPage'
import GroupList from './components/Group/List'
import UserProfile from './components/UserProfile'
import OtherUserProfile from './components/OtherUserProfile'
import DoodlesPage from './views/doodle/List'
import ProjectDetail from './components/ProjectDetail'
import ProjectUploaded from './components/ProjectUploaded'
import Coupon from './components/Coupon/Coupon'
import CouponRedeem from './components/Coupon/Redeem'
import CouponHistory from './components/Coupon/History'
import ProjectSearchPage from './components/ProjectSearchPage'
import SubscribeHistory from './components/Profile/SubscribeHistory'
import DownloadedProject from './components/Profile/DownloadedProject'
import LikedProjects from './components/Profile/LikedProjects'
import GroupDetail from './components/Group/Detail'
import VueRouter from 'vue-router'
import DoodleUploaded from './components/DoodleUploaded'
import UploadDoodlePage from './components/UploadDoodlePage.vue'
import Event from './components/Event.vue'

import MessageDetail from './components/MessageDetail.vue'
import Appeal from './components/Appeal/Detail.vue'
import Subscription from './components/Subscription/Subscription'
import SubscriptionPlanTerms from './components/Subscription/SubscriptionPlanTerms'
import PaymentSuccessPage from './components/Subscription/PaymentSuccessPage'
import SubscriptionItemSelectView from './components/Subscription/SubscriptionItemSelectView'
import PaypalView from './views/Paypal'


  export default function(){
    const routes = [
        { 
          path: '/', 
          name: 'home',
          component: Home,
        },
        { 
          path: '/login', 
          name: 'login',
          component: Home,
        },
        { 
          path: '/register', 
          name: 'register',
          component: Register,
        },
        {
          path: '/event',
          name: 'event',
          component: Event,
        },
        {
          // 当 /user/profile/:id 匹配成功，
          // UserProfile 会被渲染在 User 的 <router-view> 中
          name:"user-profile",
          path: '/profile/:id',
          component: OtherUserProfile,
          props:true,
          meta: {
            requiresAuth: false
          }
        },
        { 
          name:'project-detail',
          path: '/project/:id', 
          component: ProjectDetail,
          props:true,
          meta: {
            requiresAuth: false
          }
        },
        { 
          name:'group',
          path: '/group/:id', 
          component: GroupDetail,
          props:true,
          meta: {
            requiresAuth: true
          }
        },
        { 
          path: '/:env/paypal/subscription/:originalPlanId', 
          component: PaypalView,
          props:true,
          meta: {
            requiresAuth: true
          }
        },
        { 
          path: '/:env/subscription/terms/:paymentTool/:target/:purchaseItemKey', 
          component: SubscriptionPlanTerms,
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        { 
          path: '/:env/subscription/success', 
          component: PaymentSuccessPage,
          props: {
            success:true
          },
          meta: {
            requiresAuth: true
          }
        },
        { 
          path: '/:env/subscription/failure', 
          component: PaymentSuccessPage,
          props:  {
            success:false
          },
          meta: {
            requiresAuth: true
          }
        },
        { 
          path: '/:env/tappay/subscription/:purchaseItemKey', 
          component: Subscription,
          props: true,
          meta:{
            requiresAuth:true
          }
        },
        { 
          path: '/:env/subscription/plans/:paymentTool', 
          props: true,
          component: SubscriptionItemSelectView,
          meta: {
            requiresAuth: true
          }
        },
        { 
          path: '/doodles', 
          component: DoodlesPage,
          props:true
        },
        { 
          path: '/projects/:type', 
          component: ProjectSearchPage,
          props:true
        },
        { 
          path: '/project/:id/:editMode', 
          component: ProjectDetail,
          props:true,
          meta: {
            requiresAuth: true
          }
        },
        {

          path: '/appeal/:report_id/:id',
          component: Appeal,
          props:true,
          meta: {
            requiresAuth: true
          }
        },
        { 
            path: '/me', 
            component: UserPage,
            meta: {
              requiresAuth: true
            },
            children: [

              {
                // 当 /user/profile/:id 匹配成功，
                // UserProfile 会被渲染在 User 的 <router-view> 中
                path: 'profile/:id',
                component: UserProfile,
                props:true,
                meta: {
                  requiresAuth: true
                }
              },
              { 
                path: 'project', 
                component: ProjectUploaded,
                meta: {
                  requiresAuth: true
                }
              },
              { 
                path: 'mydoodle', 
                component: DoodleUploaded,
                meta: {
                  requiresAuth: true,
                  contractorOnly: true
                }
              },
              {
                path: 'uploaddoodle', 
                component: UploadDoodlePage,
                meta: {
                  requiresAuth: true,
                  contractorOnly: true
                }
              },
              {
                path: 'messageDetail',
                component: MessageDetail,
                meta: {
                  requiresAuth: true
                }
              },
              { 
                path: 'favorites', 
                component: LikedProjects,
                meta: {
                  requiresAuth: true
                }
              },
              { 
                path: 'downloaded', 
                component: DownloadedProject,
                meta: {
                  requiresAuth: true
                }
              },
              { 
                path: 'subscribe/history', 
                component: SubscribeHistory,
                meta: {
                  requiresAuth: true
                }
              },
              { 
                path: 'group', 
                component: GroupList,
                meta: {
                  requiresAuth: true
                }
              },
              { 
                path: 'coupon', 
                component: Coupon,
                redirect:"coupon/redeem",
                meta: {
                  requiresAuth: true
                },
                children: [
                  { 
                    path: 'redeem', 
                    component: CouponRedeem,
                    meta: {
                      requiresAuth: true
                    }
                  },
                  { 
                    path: 'history', 
                    component: CouponHistory,
                    meta: {
                      requiresAuth: true
                    }
                  },
                ]
              },
            ]
          },
        // { 
        //   path: '/login', 
        //   component: Login,
        // },
        { path: '*', component: Home },
      ]
      
      const router = new VueRouter({
        mode: 'history',
        routes
      })

      router.afterEach(() => {
        window.scroll(0, 0);
      });
    return router
  }