export default class InvoiceInfo{
    #type = 0
    #save = false
    #company_title = null
    #unified_business_no  = null
    #company_address = null
    #carrier_type = null
    #carrier_no = null
    #donation_code = null
    constructor(){
    }
    set type(value){this.#type = value}
    set save(value){this.#save = value}
    set company_title(value){this.#company_title = value}
    set unified_business_no(value){this.#unified_business_no = value}
    set company_address(value){this.#company_address = value}
    set carrier_type(value){this.#carrier_type = value}
    set carrier_no(value){this.#carrier_no = value}
    set donation_code(value){this.#donation_code = value}
    
    get type(){return this.#type}
    get save(){return this.#save}
    get company_title(){return this.#company_title}
    get unified_business_no(){return this.#unified_business_no}
    get company_address(){return this.#company_address}
    get carrier_type(){return this.#carrier_type}
    get carrier_no(){return this.#carrier_no}
    get donation_code(){return this.#donation_code}
    toJSON(){
        return {
            type: this.type,
            save: this.save,
            company_title: this.company_title,
            unified_business_no : this.unified_business_no,
            company_address : this.company_address,
            carrier_type : this.carrier_type,
            carrier_no : this.carrier_no,
            donation_code : this.donation_code
        }
    }
}