<template lang="pug">
     .container.col-6
        h3.title.font-weight-bold.mt-3.mb-2  申訴表單
        p 這邊是系統傳給你的文字
        p.text-right 這邊是你回覆的文字
        img(src="")
        textarea.form-control
      
        .form-group
            label.form__label 附加檔案
            input.form__input.form-control(type="file" accept="image/*" @change="imgChange")

        
    
        .btn.btn-edit.mt-3.ml-2(type="submit" @click="uploadDoodleBtn") {{$t("button.upload")}}
        p.typo__p(v-if="submitStatus === 'OK'") 
        p.typo__p(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
        p.typo__p(v-if="submitStatus === 'PENDING'") Sending...
</template>
<script>

export default {
    data(){
        return{
                name:undefined,
                description:undefined,
                file:undefined,
                img:undefined,
                formData:new FormData(),
                submitStatus:null,
                doodles:[]
        }
    },
    
}

</script>