<template lang="pug">
  .project_list
    .row.justify-content-center(v-if="!projects")
      .spinner-border(role="status")
        span.sr-only {{$t('label.loading')}}
    .row(v-else-if="projects && projects.length")
      .col-xl-2.col-lg-3.col-md-3.col-sm-4.col-12.p-2( v-for="project in projects")
        //- ProjectItem(:info="{uuid:i,membership:'golden',name:'project name',liked:false,machine_type:'LC50',author:{name:'author'}}")
        ProjectItem(:project="project" :allowReport="allowReport" :author="authors[project.author.uuid]",@onClickFollow="onClickFollow")
    .row.justify-content-center.text-muted(v-else)
      span {{$t('label.emptyProjectList')}}
      

</template>
<script>
import {followAuthor,unfollowAuthor} from '../api/general'
import ProjectItem from './ProjectItem'
export default {
  components:{
    ProjectItem
  },
    props:{
        projects:Array,
        allowReport:Boolean
    },
    data(){
        return{
          authors:{

          }
        }
    },
    mounted() {
    },
    methods: {
      onClickFollow(authorUUID){
      let author = this.authors[authorUUID]
      let uuid = author.uuid
      let followed = author.followed
      author.following = true
      if(followed){
        unfollowAuthor(uuid).then(res=>{
          console.log(res)
           author.followed = ! author.followed
        }).finally(()=>{
          author.following = false
        })
      }else{
        followAuthor(uuid).then(res=>{
          console.log(res)
           author.followed = !author.followed
        }).finally(()=>{
          author.following = false
          
        })
      }
    },
    },
    watch:{
      projects:{
        immediate:true,
        handler(newValue){
          if(!newValue){return}
          let authors = {}
          newValue.forEach(project => {
            let author = {}
            Object.assign(author,project.author)
            author.following = false
            authors[project.author.uuid] =author
          });
          this.authors = authors
        }
      }
    }

}
</script>
<style  scoped>
    

</style>