export default{
    codeToGenderString(code){
        switch(code){
            case 0:
                return "Gender"
            case 1:
                return "Male"
            case 2:
                return "Female"
            case 3:
                return "Others"
            default:
                return "Gender"
        }
    }
}