function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
function expired(token){
    // return token
    let jwt = parseJwt(token)
    if(jwt && jwt.exp){
        let now = new Date().getTime()
        let tokenExp = parseInt(jwt.exp)*1000 
        // let tokenExp = parseInt(jwt.exp)*1000 - 1000*60*60*24*7 + 1000*60
        console.log("token exp : ",new Date(tokenExp))
        return tokenExp <= now
    }else{
        return true
    }
}

export default {
    parseJwt,
    expired
}