<template>
        <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
            <div class="carousel-inner h-100">
                <slot></slot>
                <!-- <div class="carousel-item ">
                    <img src="@/assets/logo.png" class="d-block w-100" alt="...">
                </div> -->
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

</template>
<script>
export default {
    
}
</script>
<style lang="sass" scoped>
.carousel-control-prev
    background-color: rgba(255,255,255,0)
    background: linear-gradient(90deg, rgba(255,255,255,.7)0%, rgba(102,102,102,0.3) 80%, rgba(190,190,190,0) 100%)
.carousel-control-next
    background-color: rgba(255,255,255,0)
    background: linear-gradient(-90deg, rgba(255,255,255,.7)0%, rgba(102,102,102,0.3) 80%, rgba(190,190,190,0) 100%)
</style>