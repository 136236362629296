<template lang="pug">
  .wapper
        .py-5.container.flex-container
            SubscriptionStepNavigator(currentStepKey="2")
            .row.terms
                .col
                    .border.h-100.p-2  
                        .d-flex.justify-content-center(v-if="loading")
                            .spinner-border.m-5(role="status")
                        //- .text(v-html="termString")
                        |{{termString}}

            .row.pt-5#buttonSet.justify-content-end
                .col-md-3.col-6.px-3
                    button.w-100.btn.btn-cancel(@click="goBack") 返回
                .col-md-3.col-6.px-3
                    button.w-100.btn.btn-edit(@click="goToPaymentPage" :disabled="loading") 前往付款
</template>

<script>
import SubscriptionStepNavigator from "./SubscriptionStepNavigator";
import TermsRequest from '../../models/api/TermsRequest'
import {getTermsLink,downloadTerms} from '../../api/terms'
export default {
    components:{SubscriptionStepNavigator},
    props:{purchaseItemKey:String,env:String,target:String,paymentTool:String},
    data(){
        return{
            loading:true,
            termString:""
        }
    },
    mounted(){
        this.getTerms()
    },
    methods:{
        getTerms(){
            let target = this.target
            let lang = this.$store.getters.currentLang.id
            let version = " " // space means the latest version
            getTermsLink(new TermsRequest(lang,version,target)).then(linkRes=>{
                if(linkRes.data){
                        console.log( linkRes.data)
                    downloadTerms(linkRes.data).then(res=>{
                        this.termString = res.data
                        console.log( res.data)
                        this.loading = false
                    })
                }else{
                    this.loading = false
                }
            })
        },
        goBack(){
            this.$router.go(-1)
        },
        goToPaymentPage(){
            this.$router.push(`/${this.env}/${this.paymentTool}/subscription/${this.purchaseItemKey}`)
        }
    }
}
</script>

<style lang="sass" scoped>
.flex-container 
    display: flex
    // height: calc(100% - 90px)
    flex-direction:  column 

.terms
    min-height: 300px
    flex-grow: 1
    white-space: pre-wrap
</style>