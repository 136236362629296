
<template lang="pug">
  .container-fluid
    .row.pt-5
      .col-lg-8.col-md-10.col-12.m-auto.p-0
        SubscriptionStepNavigator(currentStepKey="1")
        .d-flex.justify-content-center(v-if="loading")
            .spinner-border.m-5(role="status")
        PlanCard(v-else v-for="(plan,i) in plans" @onItemSelected="onItemSelected" :plan="plan" :env="env" :key="i")
</template>

<script>
import SubscriptionStepNavigator from "./SubscriptionStepNavigator";
import {getPaymentPlan} from '../../api/payment'
import PlanCard from "./PlanCard";
import PlanTypeEnum from "../../models/PlanTypeEnum";
import MembershipPlan from "../../models/MembershipPlan";
// import {getPaymentTool} from '../../api/payment'
export default {
  props:["env","paymentTool"],
  components: { SubscriptionStepNavigator ,PlanCard},
  data() {
    return {
      isLoggedIn: false,
      userToken: undefined,
      loading:true,
      plans: [],
    };
  },
  mounted() {
    this.handleUserInfo();
    if (!this.token) {
      this.$alert("請先登入").then(() => {
        this.$router.go(-1);
      });
      return;
    }
    this.getPurchasablePlans();
  },
  methods: {
    onItemSelected(plan){
      this.$router.push(`/${this.env}/subscription/terms/${this.paymentTool}/${plan.membership}/${plan.plan_id}`)
        // this.$router.push(`/${this.env}/paypal/${plan.membership}`)
      // getPaymentTool().then(res=>{
      //   let {tool} = res.data
      //   console.log(res)
      //   if(!tool){
      //     this.$alert("Please try again.")
      //   }else{
      //     tool = tool.toLowerCase()
      //     if(tool == "tappay"){
      //       this.$router.push(`/${this.env}/subscription/terms/${plan.plan_id}`)
      //     }else if(tool == "paypal"){
      //        this.$router.push(`/${this.env}/paypal/${plan.membership}`)
      //     }
      //   }
      // })
    },
    handleUserInfo() {
      this.token = this.$store.getters.userToken;
    },
    getPurchasablePlans() {
      console.log("this.paymentTool",this.paymentTool)
      getPaymentPlan(this.paymentTool).then((res) => {
          this.loading = false
        console.log(res.data);
        this.handlePurchasablePlans(res.data.list);
      });
    },
    handlePurchasablePlans(plans = []) {
      this.plans = plans.map((rawPlan) => {
        let newPlan = new MembershipPlan({
          membership: this.anyStringToPlanType(rawPlan.upgrade_to_member),
          plan_id: rawPlan.plan_id,
          max_upload: rawPlan.max_upload,
          max_download: rawPlan.max_download,
          cost: rawPlan.cost,
        });
        return newPlan;
      });
    },
    anyStringToPlanType(aString) {
      for (let plan in PlanTypeEnum) {
        if (aString.toLowerCase().includes(plan)) {
          return PlanTypeEnum[plan];
        }
      }
    },
    planStyleClass(plan) {
      switch (plan) {
        case PlanTypeEnum.brozen:
          return {
            basic: true,
          };
        case PlanTypeEnum.golden:
          return {
            golden: true,
          };
      }
    },
  },
  computed:{
      PlanTypeEnum:()=>{
          return PlanTypeEnum
      }
  },
  watch: {},
};
</script>

<style lang="sass" scoped>
</style>