export default class PaymentManager {
    delegate={
        onCardUpdate:()=>{}
    }
    constructor(delegate={}){
        this.setupDelegate(delegate)
    }
    setupDelegate(delegate){
        for(let key in this.delegate){
            if(Object.prototype.hasOwnProperty.call(delegate, key)){
                this.delegate[key] = delegate[key]
            }
        }
    }
    pay() { }
    setupPaymentEnv() { }
    onCardUpdate(){}
}

