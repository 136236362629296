Date.prototype.format = function (fmt) {
    var o = {
      "M+": this.getMonth() + 1, //月份
      "d+": this.getDate(), //日
      "h+": this.getHours(), //小時
      "m+": this.getMinutes(), //分
      "s+": this.getSeconds(), //秒
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度
      "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" +  k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" +  o[k]).substr(("" + o[k]).length)));
    return fmt;
  }
  
  Date.prototype.addSeconds = function(seconds) {
    this.setSeconds(this.getSeconds() + seconds);
    return this;
  }
  
  Date.prototype.addMinutes = function(minutes) {
    this.setMinutes(this.getMinutes() + minutes);
    return this;
  }
  
  Date.prototype.addHours = function(hours) {
    this.setHours(this.getHours() + hours);
    return this;
  }
  
  Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + days);
    return this;
  }
  
  Date.prototype.addMonths = function(months) {
    this.setMonth(this.getMonth() + months);
    return this;
  }
  
  Date.prototype.addYears = function(years) {
    this.setFullYear(this.getFullYear() + years);
    return this;
  }
  