<template lang="pug">
    .container.pb-5
        .row.mt-4
            img.col.img-fluid(v-if="image" :src="image")
        .row
            .col.p-3(v-html="content")
</template>
    
<script>
import {getEventDetail} from '../api/event'
export default {
    // props: {
    //     imgurl:undefined
    // },
    data(){
        return {
            id: undefined,
            image: undefined, 
            content:undefined              
        }  
    },
    mounted(){
    },
    beforeMount(){
        console.log(this.$store.getters.currentLang)
        if (this.$route.query.id == null){
            console.log("home page")
        }else{
            this.id = this.$route.query.id
            console.log("event" + this.id)
            getEventDetail(this.id).then(res=>{
            console.log(res.data)
            if (this.$store.getters.currentLang.key == "zh"){
                this.content = res.data.description_ch 
                this.image = res.data.image_ch
            }else if (this.$store.getters.currentLang.key == "en"){
                this.content = res.data.description_en
                this.image = res.data.image_en
            }
        }).catch(()=>{
            this.content=this.$t("article.event_not_found")
        })
        }
 
    },
    methods:{},
}
</script>
<style scoped>

</style>