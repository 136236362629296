<template lang="pug">
  nav#navigation.navbar.navbar-expand-md.navbar-light.bg-light.border-bottom
    img.hover_pointer.mw-100.logo(src='@/assets/logo-b.png' alt='logo' srcset='' @click="goHome")
    button.navbar-toggler(type='button' data-toggle='collapse' data-target='#navbarTogglerDemo01' aria-controls='navbarTogglerDemo01' aria-expanded='false' aria-label='Toggle navigation')
        span.navbar-toggler-icon
    #navbarTogglerDemo01.collapse.navbar-collapse.justify-content-end
        .h-100.mt-md-0.mt-3
            ProjectFilter
        .float-right.ml-3
            Notification.position-relative.mt-md-0.mt-3.mr-3.d-inline-block(v-if="user")
        .float-right.ml-3
            .lang.position-relative.h-100.mt-md-0.mt-3.mr-3.d-inline-block
                .dropdown-toggle.btn-cancel.btn(id="langDrop" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                    | {{currentLang.name}}
                    .dropdown-menu.dropdown-menu-left(aria-labelledby='langDrop')
                        .dropdown-item(@click="setLang('zh')") 中文
                        .dropdown-item(@click="setLang('en')") EN
        .float-right
            .user-info.h-100.mt-md-0.mt-3.position-relative.d-inline-block(v-if="user != undefined")
                .d-flex.justify-content-end.align-items-center
                    .nav-user-name.text-right {{user.user.nickname}}
                    .dropdown-toggle.position-relative.hover_pointer(id="profileDrop" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                        img.rounded-circle.border.overflow-hidden.ml-2.nav-user-thumbnail(:src="user.user.photolink" ,alt='' ,srcset='')
                        .dropdown-menu.dropdown-menu-right(aria-labelledby='profileDrop')
                            router-link.dropdown-item( :to="'/me/profile/'+user.user.id") {{$t("utility.profile")}}
                            router-link.dropdown-item( :to="'/me/coupon/redeem'") {{$t("utility.coupon")}}
                            //- router-link.dropdown-item( :to="'/subscription'") Subscription
                            a.dropdown-item.mt-1.border-top(@click="logout" ) {{$t("button.logout")}}

            .h-100.mt-md-0.mt-3.d-inline-block(v-else)
                .btn.btn-edit-light(@click="register") {{$t("button.signup")}}
                .ml-2.btn.btn-edit(@click="login") {{$t("button.login")}}
    Login(v-if="showLogin", :onClose="closeLoginForm",:modeProp="loginFromType")
</template>
<script>
import Login from './Login'
import ProjectFilter from './ProjectFilter'
import Notification from './Notification.vue'
export default {
    components:{
        Login,ProjectFilter,Notification
    },
    data(){
        return{
            showLogin:false,
            loginFromType : "login"
        }
    },
    beforeMount(){
        console.log(this.$route.path)

    },
    mounted(){
                if (this.$route.path == "/login"){
            this.login()
            }
        // console.log(this.$router.currentRoute)
    },
    methods: {
        setLang(newLang){
            // this.$i18n.locale = newLang
            localStorage.setItem('footmark-lang',newLang);
            // this.$store.dispatch("setLang",newLang)
            this.$router.go(0)
        },
        goHome(){
            this.$router.push('/').catch(()=>{
                this.$router.go(0)
            })
        },
        login(){
            this.loginFromType = "login"
            this.showLogin = true
            // this.$store.commit("updateUser",{name:"test_Kevin"})
        },
        register(){
            this.loginFromType = "register"
            this.showLogin = true
        },
        closeLoginForm(){
            this.showLogin = false
        },
        logout(){
            this.$appBridge.logout()
            this.$store.commit("updateUser",undefined)
            window.localStorage.removeItem("cub_user")
            // this.$router.push("/")
            this.goHome()
        }
    },
    computed: {
        user(){
            return this.$store.state.user
        },
        isAppMode(){
            return window.webkit
        },
        currentLang(){
            return this.$store.getters.currentLang
        }
    }
};
</script>
<style scoped>
    #navigation {
        /* height: 55px; */
        /* padding: 5px; */
        background-color: white;
    }

    .logo {
        height: 45px;
        object-fit: contain;
    }
    .nav-user-name {
        max-width: 100px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .nav-user-thumbnail{
        overflow: hidden;
        /* background-color: red; */
        height: 40px;
        width: 40px;
        /* padding-right: 100%; 1:1 Aspect Ratio */
        position: relative; /* If you want text inside of it */
    }
    .nav-user-thumbnail:hover{
        cursor: pointer;
    }
    .login > .btn{
        /* height: 40px; */
    }
</style>