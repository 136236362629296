import instance from './instance'

export function uploadDoodle(data){
    return instance
    .put(`/api/v0/doodle/upload`,data)
  }

  export function getUploadedDoodles(name){
    return instance
    .get(`/api/v0/cd/list?displayName=${name}`)
  }