<template lang="pug">
    .container.pt-3
        .row.mb-3.justify-content-center.align-items-center.text-center
            .col-4.col-md-2 {{$t('label.project')}}
            .col-4.col-md-3 {{$t('label.name')}}
            .col-4.col-md-3 {{$t('label.author')}}
        .row.p-3.bg-light(v-if="!projects.length")
            .col.d-flex.justify-content-center {{$t('label.emptyProjectList')}}
        router-link.row.text-center.justify-content-center.align-items-center.projects.border-bottom.p-3(v-for="project in projects" :key="project.id" :class="[project.deleting?'bg-warning':'bg-light']" :to="'/project/'+project.id")
            .col-4.col-md-2.p-2
                .square
                    .inner
                        img.w-100.h-100(:src="project.image_url")
            .col-4.col-md-3.text-truncate {{project.name}}
            //- .col-3.col-md-2.overflow-auto ggsgsgsgsdgdsfdfdgsdgdgdsgsddfdgs/ndgsdgsdgdg\ndfsfd
            router-link.col-4.col-md-3(:to="getAuthorLink(project.author)" ) 
                .text-truncate.text-decoration.border-bottom.p-3 {{project.author?project.author.name:""}} 
           
        infinite-loading(
                    slot="append"
                    @infinite="infiniteHandler")
</template>

<script>
export default {
    data(){
        return {
            projects:[],
            currentPage:0,
            per:20,
            token:undefined
        }
    },
    beforeMount(){
        this.token = this.$store.state.user.token
        if(!this.token){
            this.$router.push("/")
            return
        }
    },
    methods: {
        getAuthorLink(author){
            if(!author || author.name == "Cubiio Official"){
                return "/projects/official"
            }else{
                return `/profile/${author.uuid}`
            }
        },
        infiniteHandler($controller){
                this.currentPage += 1
                this.$api.getUserDownloadedProjects(this.token,this.currentPage,this.per).then(res=>{
                    // console.log(res)
                    if(!res.data.items||!res.data.items.length){
                        $controller.complete();
                        return
                    }
                    this.projects = this.projects.concat(res.data.items.map(m=>{
                        m.deleting = false
                        return m
                    }))
                    $controller.loaded();
                })
            },
    },
}
</script>

<style lang="sass" scoped>

</style>