<template lang="pug">
.content-main.rounded
  .content-head
    .row.c-head.align-items-center.justify-content-between
      .left.d-flex.align-items-center
        img.labe.roundedl(
          v-if="project.membership.toLowerCase() == 'gold'",
          src="@/assets/golden.png",
          :alt="project.membership"
        )
        img.label.rounded(
          v-else-if="project.membership.toLowerCase() == 'silver'",
          src="@/assets/silver.png",
          :alt="project.membership"
        )
        .float-right 
          .label.label-LC50(v-if="project.machine_type == 'LC50'") {{ project.machine_type }}
          .label.label-LC35(
            v-else-if="project.machine_type == 'LC35'",
            @click="printID"
          ) {{ project.machine_type }}
      div
        .float-right.label.hover_pointer.ml-1(
          v-if="allowReport && user && project.author.uuid != user.user.id"
        )
          .dropdown
            button#dropdownMenu1.moreBtn.btn.dropdown-toggle(
              @click="printID",
              type="button",
              data-toggle="dropdown",
              aria-haspopup="true",
              aria-expanded="true"
            ) ...
            ul.dropdown-menu(aria-labelledby="dropdownMenu1")
              li
                a.dropdown-item(
                  href="#",
                  data-toggle="modal",
                  data-target="#newmodal",
                  @click="() => { reportPopupConfig.popupVisible = true; }"
                ) {{ $t('button.report') }}
          //- report modal 
          Popup(
            v-bind="reportPopupConfig",
            :largeSize="false",
            @cancel="reportPopupConfig.popupVisible = false"
          )
            template(v-slot:title) {{ $t('button.report') }} Form
            template(v-slot:body) 
              .list-group
                .d-flex.w-90.flex-column
                  h6 請勾選檢舉原因並於下方文字欄位詳細說明
                  div
                    .from-check.pl-4(v-for="option in options")
                      div(v-if="option.child")
                        label.form-check-label {{ $t(option.title) }}
                        .from-check.pl-5(v-for="child in option.child")
                          input#defaultCheck2-0.form-check-input(
                            type="radio",
                            :value="child",
                            v-model="$v.reportCategory.$model",
                            name="reportCategoryRadios"
                          )
                          label.form-check-label(for="defaultCheck2-0") {{ child.title }}
                      div(v-else)
                        input.form-check-input(
                          :id="'reportOption-'+option.category"
                          type="radio",
                          :value="option",
                          v-model="$v.reportCategory.$model",
                          name="reportCategoryRadios"
                        )
                        label.form-check-label(:for="'reportOption-'+option.category") {{ $t(option.title) }}
                    .small.text-danger(v-if="!$v.reportCategory.required") {{ $t('error.required') }}
                    .form-group
                      label.form__label {{ $t('label.subject') }}
                      input.form__input.form-control(
                        v-model.trim="$v.reportSubject.$model"
                      )
                      .small.text-danger(v-if="!$v.reportSubject.required") {{ $t('error.required') }}
                    .form-group
                      label.form__label {{ $t('label.description') }}
                      textarea.form-control(
                        v-model.trim="$v.reportDescription.$model"
                      )
                      .small.text-danger(v-if="!$v.reportDescription.required") {{ $t('error.required') }}
                    .form-group
                      input.form__input.form-control(
                        type="file",
                        multiple="multiple",
                        @change="appendReportFiles"
                      ) 
            template(v-slot:footer) 
              p.mr-2.pt-3(v-if="submitStatus === 'OK'") 
              p.mr-2.pt-3(v-if="submitStatus === 'ERROR'") Please fill the form correctly.
              p.mr-2.pt-3(v-if="submitStatus === 'PENDING'") Sending...
              button.btn.btn-cancel(
                @click="() => { reportPopupConfig.popupVisible = false; }"
              ) {{ $t('button.cancel') }}
              button.btn.btn-edit(@click="reportProject") {{ $t('button.send') }}
        .float-left.label.hover_pointer
          img.p-1.liked.rounded(
            v-if="user && project.liked",
            src="../assets/liked.png",
            alt="liked",
            @click="setLikeProject(false)"
          )
          img.p-1.rounded(
            v-else-if="user",
            :calss="{ liked: project.liked }",
            src="../assets/heart.png",
            alt="liked",
            @click="setLikeProject(true)"
          )
  .content.border-top.border-bottom
    router-link.content-pic.d-flex.justify-content-center.align-items-center(
      :to="'/project/' + project.id"
    )
      //- img.project-photo.p-2(src='@/assets/test.jpg' alt='Project Image')
      img.project-photo.p-2(:src="project.image_url", alt="Project Image")
  .content-foot
    .hover_pointer.project-name.mt-1(@click="onOpenProject")
      span.mr-1.label-NEW(v-if="isNew()") {{ $t('label.new') }}
      span {{ project.name }}
    .mt-1.d-flex.justify-content-between
      //- img.mr-2.rounded-circle(:src="project.author.photolink")
      //- span.username {{project.author.name}}
      .mr-1.username
        router-link(:to="getAuthorLink(author)") {{ author.name }}
      //- button.btn-follow.hover_pointer.float-right(
      //-   :class="{ follow: !author.followed }",
      //-   v-if="author.name != 'Cubiio Official'",
      //-   @click="onClickFollow(project)",
      //-   :disabled="author.following"
      //- ) 
      //-   span.spinner-border(v-if="author.following") 
      //-   span(v-else-if="!author.followed") Follow
      //-   span(v-else-if="author.followed") Followed
</template>

<script>
import { createReport } from "../api/report";
import { required } from "vuelidate/lib/validators";
import Popup from "../components/Popup.vue";
const options = [
  {
    category: "p",
    title: "抄襲",
    prefix: "",
  },
      {
        category: "i",
        title: "不當內容(色情暴力,詐騙廣告)",
        prefix: "[不當內容]",
      },
      {
        category: "d",
        title: "人身侮辱攻擊",
        prefix: "[人身侮辱攻擊]",
      },
  {
    category: "o",
    title: "其他",
    prefix: "",
  },
];
export default {
  components: { Popup },
  name: "projectItem",
  props: {
    author: {
      type: Object,
      default() {
        return {
          followed: false,
          following: false,
          name: "",
          uuid: "",
        };
      },
    },
    project: {},
    allowReport: {},
  }, // 切換
  data() {
    return {
      reportPopupConfig: {
        popupVisible: false,
      },
      showReport: false,
      // allowReport:true, // 切換是否可以檢舉
      reportSubject: undefined,
      reportCategory: undefined,
      reportDescription: undefined,
      formData: new FormData(),
      submitStatus: null,
    };
  },
  validations: {
    reportSubject: { required },
    reportCategory: { required },
    reportDescription: { required },
  },
  mounted() {
    // console.log(this.project)
  },
  methods: {
    printID() {
      console.log(this.project.id);
    },
    appendReportFiles(e) {
      let files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        this.formData.append("image", files[i]); //放進上傳的檔案
      }
    },
    reportProject() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.reportSubject = this.reportCategory.prefix + this.reportSubject;
        this.formData.append("category", this.reportCategory.category);
        this.formData.append("desctiption", this.reportDescription);
        this.formData.append("subject", this.reportSubject);
        this.formData.append("object_type", "p");
        this.formData.append("object", this.project.id);
        for (var pair of this.formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        createReport(this.formData)
          .then((res) => {
            console.log(res);
            if(res.data && res.data.error && res.data.reason){
              this.$alert(this.$t("article.report_limitation_reached"));
            }
            else if (res.status == 200) {
              this.submitStatus = "OK";
              this.$alert(this.$t("title.success"));
            }
          })
          .catch((e) => {
            console.log(e);
          }).finally(()=>{
              this.formData = new FormData();
              this.reportCategory = undefined;
              this.reportSubject = undefined;
              this.reportDescription = undefined;
              this.reportPopupConfig.popupVisible = false;
          });
      }
    },
    setLikeProject(like) {
      this.project.liked = like;
      let token = this.$store.state.user
        ? this.$store.state.user.token
        : undefined;
      if (!token) {
        return;
      }
      this.$api.setLikeProject(token, this.project.id, like).then((res) => {
        if (res.data && !res.data.error) {
          this.project.liked = like;
        }
      });
    },
    getAuthorLink(author) {
      if (author.name == "Cubiio Official") {
        return "/projects/official";
      } else {
        return `/profile/${author.uuid}`;
      }
    },
    isNew() {
      let now = new Date().getTime();
      let ct = new Date(this.project.created_time).getTime();
      return now - ct < 36000000;
    },
    onClickFollow(project) {
      this.$emit("onClickFollow", project.author.uuid);
    },
    onClickLike() {
      this.$emit("innerLike", this.project.projectID);
    },
    onClickAuthor() {
      this.$emit("innerAuthor", this.project.projectID);
    },
    onOpenProject() {
      this.$router.push("/project/" + this.project.id);
      // this.$emit('innerProject', this.project.id);
    },
  },
  computed: {
    options() {
      return options;
    },
    user() {
      return this.$store.state.user;
    },
  }
};
</script>

<style scoped>
.spinner-border {
  width: 15px;
  height: 15px;
}
.content-main {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
}

.content-head {
  width: 100%;
  background-color: #0a0a14;
  /* border-bottom: 1px solid #000; */
  /* height: 5vh; */
  padding: 5px 10px;
}

.c-head {
  margin: 0px;
}
.c-head img {
  height: 25px;
  width: 25px;
  object-fit: contain;
  background-color: #fff;
}

.project-photo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.label-NEW {
  /* 標籤邊框 */
  /* height: 17px; */
  /* width: 35px; */
  box-sizing: border-box;
  border-radius: 1px;
  /* 標籤字型 */
  /* line-height:rem; */
  padding: 3px;
  text-align: center;
  font-size: 0.5rem;
  font-weight: 500;
  /* 標籤顏色 */
  background: #ea4335;
  color: #fff;
  text-align: center;
}
.content {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.content-pic {
  height: 100%;
  /* padding-bottom: 90%; */
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* border-bottom: 1px solid #000; */
}

.content-foot {
  width: 100%;
  padding: 10px;
  padding-top: 0;
  /* padding-bottom: 5%; */
}
.content-foot img {
  height: 20px;
  width: 20px;
}

.name-1 {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.username {
  font-size: 0.9rem;
  font-weight: 500;
  padding-top: 5px;
  overflow: scroll;
}

.btn-follow {
  /* margin: auto 10px auto auto; */
  /* 標籤邊框 */
  padding: 3px;
  /* height: 17px; */
  /* width: 35px; */
  border: 1px solid #ea4335;
  box-sizing: border-box;
  border-radius: 1px;
  /* 標籤字型 */
  /* line-height: 1.5rem; */
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  /* 標籤顏色 */
  background: #fff;
  color: #ea4335;
}
.btn-follow.follow {
  color: #c3c3c3;
  border: 1px solid #c3c3c3;
}
.project-name {
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  /* line-height: 0px; */
  width: 100%;
  height: 2.3rem;
  overflow: scroll;
  /* box-shadow: inset 0 -10px 10px -10px #000000; */
}
.liked {
  background-color: #fad0d0;
}
.dropdown-toggle::after {
  content: none;
}
.moreBtn {
  width: 25px;
  height: 25px;
  padding: 0;
  font-weight: 900;
  color: rgb(176, 176, 176);
  background-color: white;
  /* line-height: 10px; */
}
</style>